import './orders-list.scss'

const OrdersList = ({orders}) => { 
    const ordersProducts = orders.map((item, i) => {
return (        
    <div key={i}>
     <h2>Заказ <span className='order-id'>{item._id}</span></h2>
            <ul className="orders-list"> 
               
                { item.product.map((product, i) => {
                        const{main_photo_link, name_of_product, quantity, weight_in_packaging_g , price_rubles, price_before_discount} = product
                        return (
                        <>
                                
                                <li className='product' key={i}>
                                    <img src={main_photo_link} className='product-photo' alt={name_of_product} 
                                    // onClick={() => openLink(cart)}
                                    />
                                    <div className='about-product' 
                                    // onClick={() => openLink(cart)}
                                    >
                                        <h2 className='name_product'>{name_of_product}</h2>
                                        <span>{weight_in_packaging_g} г.</span>
                                    </div>

                                    <span className='quantity'>{quantity}</span>
                            
                                    <div className='price-product'>
                                        <span className='before-price'>{price_before_discount} ₽</span>
                                        <span className='after-price'>{price_rubles} ₽</span>
                                    </div>
                                </li>
                        </>
                        )
                    })}
                <li className='orders-status'>
                    <div className='orders-head'>
                        <h3>Статус заказа:</h3>   
                    </div>                 
                    <div className='status'>
                       {item.status}
                    </div>
                </li>
           </ul>
        </div>
           )
    })

    return(
        <>
            {ordersProducts}
        </>
    )
}

export default OrdersList