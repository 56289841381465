import AppHeader from '../appHeader/app-header';
import CatalogMenu from '../catalogMenu/catalog-menu';
import ProductsList from '../productsList/products-list';
import Filter from '../filter/filter';
import Footer from '../footer/footer'; 
import { Helmet } from 'react-helmet';
import Feedback from '../feedback/feedback'; 
import AllOrders from '../allOrders/allOrders';   
import { ClipLoader } from 'react-spinners';
import AppHeaderMainPage from '../appHeaderMainPage/app-header-main-page';


const AllOrdersPages = ({useUser}) => {
  
            return(
                 <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Все заказы</title> 
                    </Helmet>
                    <AppHeaderMainPage/>
                    <AppHeader/>
         
                    <main>
                        <div className='container'>
                            {/* <ClipLoader color="#FFB701"   cssOverride={{
                                            width: '100px',
                                            height: '100px',
                                            marginLeft: '350px',
                                            marginTop: '100px' 
                                        }} />   */}
                            <AllOrders useUser={useUser}/>
                        </div>
                    
                    </main>
                    <Feedback/>
                    <Footer/>
                </>
            )
      
}

export default AllOrdersPages;