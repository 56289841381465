import likeCard from '../../resources/img/ico/like-card.svg';
import profile from '../../resources/img/ico/profile.svg';
import info from '../../resources/img/ico/info.svg';
import orders from '../../resources/img/ico/orders.svg';
import {Link} from "react-router-dom";
import './mobile-menu.scss'

const MobileMenu = () => {

    return(
        <ul className="mobile-menu">
            <li> 
                <Link to='/orders'><img src={orders} alt="orders"/></Link> 
            </li> 
            <li>
                <Link to='/me'> <img src={profile} alt="profile"/></Link>
            </li>
            <li>
                <Link to='/information'><img src={info} alt="info"/></Link> 
            </li>
            <li> 
                <Link to='/favourites'><img src={likeCard} alt="like"/></Link> 
            </li> 
        </ul>
    )
}

export default MobileMenu;