import plus from '../../resources/img/ico/plus.svg';
import minus from '../../resources/img/ico/minus.svg';
import React from 'react';
import useCounter from '../../hooks/useCounter';
import useFavourites from '../../hooks/useFavourites';
import likeCard from '../../resources/img/ico/like-card.svg';
import likeCard__active from '../../resources/img/ico/like-card__active.svg'; 
import CartCard from '../../resources/img/ico/cart-card.svg';
import CartCard__active from '../../resources/img/ico/cart-card__active.svg';
import { Link } from 'react-router-dom';

const ProductCounter = ({ item }) => { 
  const { cartItems, handleAddToCart, handleRemoveFromCart, handleToggleCart } = useCounter();
  const { favourites, toggleFavourite } = useFavourites();


  const cartItem = cartItems.find((cartItem) => cartItem.article === item.article);
  const quantity = cartItem ? cartItem.quantity : 0;


  const handleIncrement = () => {
    handleAddToCart(item); 
  };

  const handleDecrement = () => {
    handleRemoveFromCart(item);
  };    

  const handleToggleFavourite = () => {
    toggleFavourite(item);
  };
 
  const {price_before_discount, price_rubles, article, name_of_product} = item;
  const isFavourite = (elem) => {return elem.some((favourite) => favourite.article === article)}



  return (
    <> 
      <div className='panel-product'>
          <div className='price-product'>
              <h3>Цена</h3>
              <span className='before-price'>{price_before_discount ? `${price_before_discount} ₽` : null} </span>
              <span className='after-price'>{price_rubles} ₽</span>
          </div>
          <div className='added-panel'>
              <button onClick={handleToggleFavourite}>
                  <img src={ isFavourite(favourites) ? likeCard__active :likeCard} alt='like-product' className='like-product' />
              </button>
              <button onClick={() => {  
                                      handleToggleCart(item)}}>
                  <img src={isFavourite(cartItems) ? CartCard__active : CartCard} alt='cart-product' className='cart-product' />
              </button>
          </div>
          {isFavourite(cartItems) ?  
                  <> 
                      <div className='count-product'>
                        <button onClick={handleDecrement}>
                          <img src={minus} alt='minus-product' className='minus-product' />
                        </button>
                        <span className='count'>{quantity}</span>
                        <button onClick={handleIncrement}>
                          <img src={plus} alt='plus-product' className='plus-product' />
                        </button>
                      </div>
                      <span className='check-cart'>Перейти в <Link to='/cart'>корзину</Link></span>
                  </>: null} 
    </div> 
    </>
  );
};

export default ProductCounter;
