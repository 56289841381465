
import { useState } from 'react';
import openLink from '../productsList/openLink';
import ProductCounter from '../productsList/productCounter';  
import React from 'react'; 
import './favourites-products.scss';

const FavouritesProducts = ({favourites, toggleFavourite}) => {
  const [visibleProducts, setVisibleProducts] = useState(9);
 
  const [sortOption, setSortOption] = useState(''); 
  const showMoreProducts = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 9);
  };

  const handleSortChange = (event) => {
    const selectedSortOption = event.target.value;
    setSortOption(selectedSortOption);
  };

  const getSortedProducts = () => {
    let sortedProducts = [...favourites];

    if (sortOption === 'priceAsc') {
      sortedProducts.sort((a, b) => a.price_rubles - b.price_rubles);
    } else if (sortOption === 'priceDesc') {
      sortedProducts.sort((a, b) => b.price_rubles - a.price_rubles);
    } else if (sortOption === 'nameAsc') {
      sortedProducts.sort((a, b) => a.name_of_product.localeCompare(b.name_of_product));
    }

    return sortedProducts;
  };

  const sortedProducts = getSortedProducts();

  const visibleSortedProducts = sortedProducts.slice(0, visibleProducts);

  const elemProducts = visibleSortedProducts.map((item) => {
        const {
          annotation,
          article,
          name_of_product,
          main_photo_link, 
        } = item;
     
 
        return (
          <li key={article} className="product">
            <img src={main_photo_link} alt={name_of_product} onClick={() => openLink(item)} />
            <div className="about-product">
              <h2 onClick={() => openLink(item)}>{name_of_product}</h2>
              <div className="text__about-product" onClick={() => openLink(item)}>
                {annotation}
                <div></div>
              </div>
            </div>
            <ProductCounter
                  item={item} 
              />
          </li>
        );
  });

  const showMoreButton =
  favourites && visibleProducts < sortedProducts.length ? (
    <button className="show-more__btn" onClick={showMoreProducts}>
      Показать еще
    </button>
  ) : null; 
  return (
    <div className="products-list"> 
      <div className="sort_catalog-pages-head">
        <div>
         
          <select value={sortOption} onChange={handleSortChange}>
            <option value="">Выберите фильтр</option>
            <option value="priceAsc">По возрастанию</option>
            <option value="priceDesc">По убыванию</option>
            <option value="nameAsc">По наименованию</option> 
          </select> 
        </div> 
      </div>
      <ul className="products">{elemProducts}</ul>
        {showMoreButton}
    </div>
  );
  
};

export default FavouritesProducts;


 