import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'; 
import ProductCounter from "../../../productsList/productCounter"; 
import './../../../StylesProduct/description.scss'


const Description = ({productPlate}) => { 
    const elem = (productPlate) => {
        const {name_of_product, annotation, price_rubles, price_before_discount, 
            main_photo_link, additional_photo_links, article} = productPlate;
        

        return(
            <div className="description">
                <div className="carousel-slide">
                    <Carousel showStatus={false}>
                        <div>
                            <img src={main_photo_link} alt={name_of_product}/>
                        </div> 
                    </Carousel> 
                </div>
                <div className="definition">
                 
                    <ProductCounter
                        item={productPlate} 
                    /> 
                    <div className="definition__description">
                        <div>{annotation}</div>
                    </div>

                </div>
            </div>
        )
    }
   
    const item = productPlate ? elem(productPlate[0]) : null
    return (
       <>
         {item}
       </>
    )
}

export default Description;