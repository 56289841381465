import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'; 
import useCounter from "../../../../hooks/useCounter";
import useFavourites from "../../../../hooks/useFavourites";
import ProductCounter from "../../../productsList/productCounter"; 
import './../../../StylesProduct/description.scss'


const Description = ({productConditionerProtection}) => {
    const { cartItems, handleAddToCart, handleRemoveFromCart, handleToggleCart } = useCounter();
    const { favourites, toggleFavourite } = useFavourites();

    const elem = (productConditionerProtection) => {
        const {name_of_product, annotation, price_rubles, price_before_discount, 
            main_photo_link, additional_photo_links, supported_brands, list_of_compatible_devices, purpose, article} = productConditionerProtection;
 
        const regex = /(https:\/\/.*?\.jpg)/g;
        const matches = [...additional_photo_links.matchAll(regex)].map((match) => ({ url: match[1] }));
 
        const imgs = matches.map((item,i) => {
            return(
                <div key={i}>
                    <img src={item.url} alt={name_of_product}/>
                </div>
        
            )
        }) 

        return(
            <div className="description">
                <div className="carousel-slide">
                    <Carousel showStatus={false}>
                        <div>
                            <img src={main_photo_link} alt={name_of_product}/>
                        </div>
                        {imgs}
                    </Carousel>
 
                </div>
                <div className="definition">
                    <ProductCounter
                        item={productConditionerProtection} 
                    />
                    <div className="definition__description">
                        <div>{annotation}</div>
                        <div className="supports-brend"><span>Предназначение:</span>{purpose}</div>
                        <div className="supports-brend"><span>Список совместимых устройств:</span>{list_of_compatible_devices}</div>
                        <div className="supports-brend"><span>Поддерживаемые бренды:</span>{supported_brands}</div>
                    </div>

                </div>
            </div>
        )
    }
   
    const item = productConditionerProtection ? elem(productConditionerProtection[0]) : null
    return (
       <>
         {item}
       </>
    )
}

export default Description;