import React, { useState } from "react";
import Modal from "react-modal";
import { useForm, Controller} from "react-hook-form";
import padlock from '../../resources/img/ico/padlock.svg';
import close from './../../resources/img/ico/close.svg'; 
import './modal.scss'


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "100vw",
    height: "100vh",
    borderRadius: 10,
    zIndex: 1
  },
};

function ModalCorusel({modalOpen, setModalOpen}) {

 
 

 
 
 
  return (
    <div className="modal-order">
  
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
        ariaHideApp={false}
     
      >
        <form className="address-form"  >
         
        </form>
        <button className="close-modal" onClick={() => setModalOpen(false)}>
          <img src={close} alt="close-modal"/>
        </button>
  
      </Modal>
    </div>
  );
}

export default ModalCorusel;