import { useEffect, useState } from "react";
import useMarketService from "../../services/market-services";
import React from 'react';
import { Badge, Descriptions } from 'antd';
import './allOrders.scss'
import CalendarItem from "../calendar/calendar";
import moment from 'moment'

const AllOrders = ({useUser}) =>{ 
    const { user } = useUser();
    const { getAllOrders, error } = useMarketService();
    const [orders, setOrders] = useState([]);
    const [date, setDate] = useState('');
    const [originalOrders, setOriginalOrders] = useState([]); // Добавляем переменную для хранения оригинального массива
  
    useEffect(() => {
      getAllOrders(user).then((data) => {
        setOrders(data);
        setOriginalOrders(data); // Сохраняем оригинальный массив при получении данных
      });
    }, [user]);
  
    useEffect(() => {
      console.log(orders);
        setOrders(originalOrders.filter((obj) => { // Фильтруем оригинальный массив
            let objDate = obj.createdAt.split("T")[0];
            return objDate === date;
          }))
      // Здесь можно использовать отфильтрованный массив filteredData по вашему усмотрению
  
    }, [date]);
     console.log(originalOrders)
  
 
    const elem = orders.map((item, i)=> {
        const {lname, fname, lfname, _id, createdAt, addressPost, chosenPost, pricePost, product} = item
        const originalDate = new Date(createdAt);
        const europeanDate = originalDate.toLocaleDateString("en-GB");
        return (
            <div key={i}>
                <Descriptions title={`Номер заказа: ${_id}` } bordered>
                <Descriptions.Item label="Имя">{`${lname, fname, lfname}`}</Descriptions.Item> 
                <Descriptions.Item label="Дата создания" span={2}>
                {europeanDate}
                </Descriptions.Item>
                <Descriptions.Item label="Адрес">{addressPost}</Descriptions.Item>
                <Descriptions.Item label="Номер поста">{chosenPost}</Descriptions.Item>
                <Descriptions.Item label="Цена Д.">{pricePost}</Descriptions.Item>
            
        
                <Descriptions.Item label="Заказы">
                    {product.map(prod => {
                        return (
                            <>
                                {`${prod.name_of_product} ${prod.article} Кол-во: ${prod.quantity}  Цена: ${prod.price_rubles}руб.`}
                            </>
                        )
                    })}
                </Descriptions.Item>
                </Descriptions>
            </div>
        )
    })
      
  

    return(
        <div className="all-orders"> 
            <label htmlFor="date">Укажите дату создания заказа  </label>
            <input type="date" onSelect={(e)=>setDate(e.target.value)}/>
            {elem}
        </div>
    )
}

export default AllOrders;