import { Helmet } from 'react-helmet';
import AppHeader from '../../appHeader/app-header';
import CartMenu from '../../cart-menu/cart-menu';
import AccountSettings from '../../accountSettings/accountSettings';
import Feedback from '../../feedback/feedback';
import Footer from '../../footer/footer';
import arrowCategories from '../../../resources/img/ico/arrow__catalog-menu.svg'; 
import useMarketService from '../../../services/market-services';
import { Navigate } from "react-router-dom";
import './myAccount.scss'
import { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import MobileMenu from '../../mobileMenu/mobile-menu';
import CatalogMenu from '../../catalogMenu/catalog-menu';

const MyAccount = ({useUser}) => {
    const {user, handleAddToUser} = useUser() 
    const [redirectToAuthorization, setRedirectToAuthorization] = useState(false);
    const {getUser} = useMarketService()
    const [userData, setUserData] = useState(null)
    const [state, setState] = useState(false)
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      // Создаем медиа-запрос для экранов с максимальной шириной 768px
      const mediaQuery = window.matchMedia('(max-width: 768px)');
  
      // Функция обратного вызова, которая будет вызвана при изменении медиа-запроса
      const handleMediaQueryChange = (event) => {
        setIsMobile(event.matches);
      };
  
      // Добавляем слушатель для изменений медиа-запроса
      mediaQuery.addListener(handleMediaQueryChange);
  
      // Вызываем обработчик медиа-запроса сразу при монтировании компонента
      handleMediaQueryChange(mediaQuery);
  
      // Очищаем слушатель при размонтировании компонента
      return () => {
        mediaQuery.removeListener(handleMediaQueryChange);
      };
    }, []);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        if (!user) {
          setRedirectToAuthorization(true);

        }else{ 
            const data = {
                userId: user
            }
            getUser(data)
                .then(res => setUserData(res))
                .catch(e => {
                    handleAddToUser(null)
                })
        }
      }, 1000);
  
      return () => clearTimeout(timer);
    }, [user]);
  
    if (redirectToAuthorization) {
        
      return <Navigate to="/authorization" replace={true} />;
    }
   
    return(
        <div className="my-account">
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Профиль</title> 
                </Helmet>
                <AppHeader setState={setState} state={state}/>
                <main>
                <div className='container'>
                    <div className='row'>
                        <div className='col-left__main'>
                        {isMobile ?   <CatalogMenu  setState={setState} state={state}/>  : null}
                            <h1>Настройки</h1>
                            <CartMenu/>
                    
                        </div>
                        <div className='col-right__main'>
                            <ul className='categories'>
                                <li>
                                    <a href='/'>Главная</a>
                                </li>
                                    <img src={arrowCategories} alt='arrow__catalog-menu'/>
                                <li>
                                    <a href='/me'>Настройки</a>
                                </li>
                            </ul>
                        {userData ? <AccountSettings user={user} handleAddToUser={handleAddToUser} userData={userData}/> :      <ClipLoader color="#FFB701"   cssOverride={{
                                            width: '100px',
                                            height: '100px',
                                            marginLeft: '350px',
                                            marginTop: '100px' 
                                        }} /> }
                        </div> 
                    
                    </div>
                    <Feedback/> 
                </div>
                </main>
                <MobileMenu/>
                <Footer/>
            </>
        </div>
    )

}

export default MyAccount;