import AppHeader from "../../appHeader/app-header";
import { Helmet } from "react-helmet";
import Feedback from "react-bootstrap/esm/Feedback";
import Footer from "../../footer/footer";
import useMarketService from "../../../services/market-services";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import CartMenu from "../../cart-menu/cart-menu";
import { ClipLoader } from 'react-spinners';
import OrdersList from "../../ordersList/orders-list";
import arrowCategories from '../../../resources/img/ico/arrow__catalog-menu.svg';
import MobileMenu from '../../mobileMenu/mobile-menu';
import CatalogMenu from "../../catalogMenu/catalog-menu";


const Orders = ({useUser}) => {
    const {user} = useUser()
    const [redirectToAuthorization, setRedirectToAuthorization] = useState(false);
    const [state, setState] = useState(false)
    const {getOrders, getProducts} = useMarketService()
    const[orders, setOrders] = useState(null) 
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          if (!user) {
            setRedirectToAuthorization(true);
  
          }else{ 
              const data = {
                  userId: user
              }
              getOrders(data).then(orders => { 
                getProducts('allproducts').then(products => {
                  const updatedOrders = orders.map(order => {
                    const updatedProduct = order.product.map(orderProduct => {
                        const foundProduct = products.find(product => product.article === orderProduct.article);
                        if (foundProduct) {
                          return { ...foundProduct, quantity: orderProduct.quantity };
                        } else {
                          return orderProduct;
                        }
                      });
                      
              
                    return { ...order, product: updatedProduct };
                  });
               
                  setOrders(updatedOrders);
                });
              });
              
              
              
          }
        }, 1000);
    
        return () => clearTimeout(timer);
      }, [user]);

      useEffect(() => {
        // Создаем медиа-запрос для экранов с максимальной шириной 768px
        const mediaQuery = window.matchMedia('(max-width: 768px)');
    
        // Функция обратного вызова, которая будет вызвана при изменении медиа-запроса
        const handleMediaQueryChange = (event) => {
          setIsMobile(event.matches);
        };
    
        // Добавляем слушатель для изменений медиа-запроса
        mediaQuery.addListener(handleMediaQueryChange);
    
        // Вызываем обработчик медиа-запроса сразу при монтировании компонента
        handleMediaQueryChange(mediaQuery);
    
        // Очищаем слушатель при размонтировании компонента
        return () => {
          mediaQuery.removeListener(handleMediaQueryChange);
        };
      }, []);
    
      if (redirectToAuthorization) {
          
        return <Navigate to="/authorization" replace={true} />;
      } 
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Заказы</title> 
            </Helmet>
            <div className="orders">
            <AppHeader setState={setState} state={state}/>

                <main>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-left__main'>
                                    {isMobile ?   <CatalogMenu  setState={setState} state={state}/>  : null}
                                    <h1>Заказы</h1>
                                    <CartMenu/>
                                 
                                </div>
                                <div className='col-right__main'>
                                    <ul className='categories'>
                                        <li>
                                            <a href='/'>Главная</a>
                                        </li>
                                            <img src={arrowCategories} alt='arrow__catalog-menu'/>
                                        <li>
                                            <a href='/orders'>Заказы</a>
                                        </li>
                                    </ul>
                                    {orders  ?  <OrdersList orders ={orders} /> :      <ClipLoader color="#FFB701"   cssOverride={{
                                                width: '100px',
                                                height: '100px',
                                                marginLeft: '350px',
                                                marginTop: '100px' 
                                            }} /> }

                                </div>  
                            </div>
                        </div>
                    </main>
                    <Feedback/>
                    <MobileMenu/>
                    <Footer/>
            </div>
        </>
    )
}


export default Orders;