import { useEffect, useState } from 'react';

const useAddress = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = localStorage.user; 
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const handleStorageChange = (event) => {
    if (event.key === 'user') {
      setUser(JSON.parse(event.newValue));
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleAddToUser = (newUser) => {
    localStorage.user = JSON.stringify(newUser);
    setUser(newUser);
  };

  return {
    user,
    handleAddToUser,
  };
};

export default useAddress;
