import './../../../StylesProduct/configuration.scss'

const Configuration = ({productPlate}) => {
 
    return(
        <ul className='configuration'> 
            <li >
                {productPlate[0].package_contents}  
            </li> 
        </ul>
    )
}

export default Configuration;