 
import AppHeader from '../appHeader/app-header';
import CatalogMenu from '../catalogMenu/catalog-menu'; 
import Footer from '../footer/footer';
import Feedback from '../feedback/feedback';
import AppHeaderMainPage from '../appHeaderMainPage/app-header-main-page';
import {useState} from "react"
import MobileMenu from '../mobileMenu/mobile-menu'; 
import ProductMemory from '../productMemory/product-memory';

const ProductPageMemory = () => {
    const [state, setState] = useState(false)

 
    return(
        <div className="product-page">
            <AppHeaderMainPage/>
            <AppHeader setState={setState} state={state}/>
            <main>
                <div className='container'>
                    <div className='row'>
                        <div className='col-left__main'>
                            <CatalogMenu  setState={setState} state={state}/>
                        </div>
                        <div className='col-right__main'> 
                            <ProductMemory/>
                        </div>  
                    </div>
                </div>
            </main>
            <Feedback/>
            <MobileMenu/>
            <Footer/>
        </div>
    )
}

export default ProductPageMemory;