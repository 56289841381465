import avatar from './../../resources/img/ico/avatar.svg'
import { useForm, Controller} from "react-hook-form";
import './accountSettings.scss'
import useMarketService from '../../services/market-services';
import { useState } from 'react';


const AccountSettings = ({user, handleAddToUser, userData}) => {
    const {setName, errorMessage} = useMarketService()
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const [res, setRes] = useState()
      const onSubmit = (data ) => { 
         setName(user, data).then( setRes('Данные сохранены. Обновите страницу.'))
      }

    const { lname, fname, lfname, number, createdAt, email } = userData


      const date = createdAt.slice(8, 10); // Извлекаем день
      const month = createdAt.slice(5, 7); // Извлекаем месяц
      const year = createdAt.slice(0, 4); // Извлекаем год
      const time = createdAt.slice(11, 16); // Извлекаем время
      
      const formattedDate = `${date}.${month}.${year} ${time}`;
 
    return (
        <div className="account-settings">
            <div className="account">
                <img src={avatar} alt="avatar" className='account__avatar'/>
                <div className="account-info">
                    {lname && fname && lfname ? <p>{`${lname} ${fname} ${lfname}`}</p> : <p>Имя не указано</p>}
                    <span className='account-info__number'>{number}</span>
                     {email ?  <span>{email}</span> : null } 
                </div>
                <p onClick={() => {handleAddToUser(null)}} className='account-exit'>Выйти</p>
            </div>
            <div className="change-setting">
                <h2>Изменить данныe</h2>
                <div>
                    <div className="change-avatar">
                        <img src={avatar} alt="change-avatar" className='change-settings__avatar' />
                        <button disabled={true} className='btn-download'>Загрузить</button>
                    </div>
                    <form className="change-name" onSubmit={handleSubmit(onSubmit)}>
                        <div className='input-label'>
                            <label htmlFor="number">
                                Имя 
                            </label>
                            <input id="name" type="text" placeholder="Иван" className="first-name"  {...register("fname", {required: true, maxLength: 20})} />
                        </div>
                        <div className='input-label'>
                            <label htmlFor="number">
                                Фамилия
                                <input id="name" type="text" placeholder="Иванов" className="first-name" {...register("lname", {required: true, maxLength: 80})} />
                            </label>
                        </div>
                        <div className='input-label'>
                            <label htmlFor="number">
                                Отчество
                                <input id="name" type="text" placeholder="Иванович" className="first-name" {...register("lfname", {required: true, maxLength: 80})} />
                        
                            </label>
                        </div>
                        <div className='input-label'>
                            <label htmlFor="number">
                                Эл.почта
                                <input id="name" type="email" placeholder="nefull@bk.ru" className="first-name" {...register("email", {required: true, maxLength: 80})} />
                        
                            </label>
                        </div>
                        {errorMessage ? <p>{errorMessage}</p> : res}
                        <button type='submit'>Сохранить</button>
                    </form>
                </div>
            </div>
            <div className="safety">
                <h2>Изменить пароль</h2>
                <Safety number={number}/>
            </div>
            <div className="date-of-reg">
                <p>Дата регистрации: {formattedDate}</p>
            </div>
        </div>
    )
}


const Safety = ({number}) => {
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const {resetPassword, errorMessage} = useMarketService()
    const [res, setRes] = useState()

    const onSubmit = (data) => {
        resetPassword(data).then(setRes('Пароль изменен'))
    }
    return(
    <form className="change-login" onSubmit={handleSubmit(onSubmit)}>
   
        <div className='input-label'>
            <label htmlFor="number">
            Телефон 
            </label>
            <input id="number" type="tel" placeholder="+7 999 999 99 99" className="number" defaultValue={number} {...register("number", {required: true,   minLength: 11,maxLength: 12})} />
        </div>
        <div className='input-label'>
            <label htmlFor="number">
               Пароль
            </label>
            <input id="name" type="password" placeholder="Пароль"    {...register("password", {required: true, minLength: 6, maxLength: 20 })} />
        </div>
        {errorMessage ? <p>{errorMessage}</p> : res}
        <button type='submit'>Сменить пароль</button>
    </form>
    )
}

export default AccountSettings;