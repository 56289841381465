import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'; 
import useCounter from "../../../../hooks/useCounter";
import useFavourites from "../../../../hooks/useFavourites";
import ProductCounter from "../../../productsList/productCounter"; 
import './../../../StylesProduct/description.scss'


const Description = ({productMemory}) => {
    const { cartItems, handleAddToCart, handleRemoveFromCart, handleToggleCart } = useCounter();
    const { favourites, toggleFavourite } = useFavourites();

    const elem = (productMemory) => {
        const {name_of_product, annotation, price_rubles, price_before_discount, 
            main_photo_link, additional_photo_links, supported_brands, list_of_compatible_devices, purpose, article} = productMemory; 
            const photoLinksArray = additional_photo_links.split("\n");
           
            const imgs = photoLinksArray.map((url, i) => (
                <div key={i}>
                    <img src={url} alt={`Photo ${i + 1}`} />
                </div>
            ));
            
        return(
            <div className="description">
                <div className="carousel-slide">
                    <Carousel showStatus={false}>
                        <div>
                            <img src={main_photo_link} alt={name_of_product}/>
                        </div>
                        {imgs}
                    </Carousel>
 
                </div>
                <div className="definition">
                    <ProductCounter
                        item={productMemory} 
                    />
                    <div className="definition__description">
                        <div>{annotation}</div>
                         
                    </div>

                </div>
            </div>
        )
    }
   
    const item = productMemory ? elem(productMemory[0]) : null
    return (
       <>
         {item}
       </>
    )
}

export default Description;