import './../../../StylesProduct/specifications.scss'

const Specifications = ({productPorches}) => {
    console.log(productPorches)
    const {
        dimensions_mm, weight_in_packaging_g, color_name, height_mm, length_mm, width_mm,
        quantity_in_packaging, warranty_period, 
            type, purpose,st_num, 
            material, country_of_manufacture
          } = productPorches[0]
 
    return(
        <div className="specifications">
            <h2>Характеристики</h2>
            <ul>
                <li>
                    <span>Размеры(ВхШхД)</span>
                    <span>{`${height_mm ? height_mm : ''}x${ length_mm ? length_mm : ''}x${width_mm ? width_mm : ''}`} см</span>
                </li>

                <li>
                    <span>Вес</span>
                    <span>{weight_in_packaging_g} г</span>
                </li>
                <li>
                    <span>Цвет</span>
                    <span>{color_name}</span>
                </li>
                <li>
                    <span>Тип</span>
                    <span>{type}</span>
                </li>
                <li>
                    <span>Материал</span>
                    <span>{material}</span>
                </li>
                <li>
                    <span>Количество в упаковке</span>
                    <span>{quantity_in_packaging}</span>
                </li>
                <li>
                    <span>Гарантия</span>
                    <span>1 год</span>
                </li>
                <li>
                    <span>Страна производитель</span>
                    <span>{country_of_manufacture}</span>
                </li>
            </ul>
        </div>
    )
}

export default Specifications;