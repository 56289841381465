import { useState, useEffect } from 'react';

const CategoriesModel = ({ setProduct, product, originalProduct }) => {
    const [models, setModels] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [colors, setColors] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState(originalProduct);

    useEffect(() => {
        if (originalProduct[0]?.model_name_for_merging_into_one_card) {
            const uniqueKeyArray = [];
            originalProduct.forEach((item) => {
                const key = item.model_name_for_merging_into_one_card;
                if (!uniqueKeyArray.includes(key)) {
                    uniqueKeyArray.push(key);
                }
            });
            setModels(uniqueKeyArray);
        }
    }, [originalProduct]);

    useEffect(() => {
        if (selectedModel) {
            const filteredByModel = originalProduct.filter(product => product.model_name_for_merging_into_one_card === selectedModel);
            setFilteredProducts(filteredByModel);
            setProduct(filteredByModel);

            const uniqueSizeKeyArray = [];
            filteredByModel.forEach((item) => {
                const width = item.width_mm;
                const height = item.height_mm;
                const existingItem = uniqueSizeKeyArray.find(
                    (uniqueItem) => uniqueItem.width_mm === width && uniqueItem.height_mm === height
                );
                if (!existingItem) {
                    uniqueSizeKeyArray.push({ width_mm: width, height_mm: height });
                }
            });

            setSizes(uniqueSizeKeyArray.sort((a, b) => {
                if (a.height_mm === b.height_mm) {
                    return a.width_mm - b.width_mm;
                }
                return a.height_mm - b.height_mm;
            }));
        } else {
            setSizes([]);
            setColors([]);
        }
    }, [selectedModel, originalProduct]);

    useEffect(() => {
        if (selectedSize) {
            const { width_mm, height_mm } = selectedSize;
            const filteredBySize = filteredProducts.filter(item => item.width_mm === width_mm && item.height_mm === height_mm);
            setProduct(filteredBySize);

            const uniqueColorArray = [];
            filteredBySize.forEach((item) => {
                const productColor = item.product_color;
                if (!uniqueColorArray.includes(productColor)) {
                    uniqueColorArray.push(productColor);
                }
            });

            setColors(uniqueColorArray.sort());
        } else {
            setColors([]);
            if (selectedModel) {
                setProduct(filteredProducts);
            }
        }
    }, [selectedSize, filteredProducts]);

    function onSetModel(e, model) {
        const array = Array.from(e.target.parentElement.parentElement.children);
        array.forEach(child => {
            child.children[0].style.border = 'none';
        });
        e.target.style.border = '2px solid #FFB701';

        setSelectedModel(model);
        setSelectedSize(null);
        setColors([]);
    }

    function onSetSize(e, sizeW, sizeH) {
        const array = Array.from(e.target.parentElement.parentElement.children);
        array.forEach(child => {
            child.children[0].style.border = 'none';
        });
        e.target.style.border = '2px solid #FFB701';

        setSelectedSize({ width_mm: sizeW, height_mm: sizeH });
    }

    function onSetColor(e, color) {
        const array = Array.from(e.target.parentElement.parentElement.children);
        array.forEach(child => {
            child.children[0].style.border = 'none';
        });
        e.target.style.border = '2px solid #FFB701';

        const { width_mm, height_mm } = selectedSize;
        const filteredByColor = filteredProducts.filter(product => 
            product.product_color === color && product.width_mm === width_mm && product.height_mm === height_mm
        );
        setProduct(filteredByColor);
    }

    return (
        <>
            {models.length ? <h3 style={{ margin: '10px 0 0 10px' }}>Модель:</h3> : null}
            <ul className="categories-model">
                {models.map((model, i) => (
                    <li key={i} className="model">
                        <button onClick={(e) => onSetModel(e, model)}>
                            {model}
                        </button>
                    </li>
                ))}
            </ul>

            {selectedModel && sizes.length ? <h3 style={{ margin: '10px 0 0 10px' }}>Размер мм:</h3> : null}
            {selectedModel && (
                <ul className="categories-model">
                    {sizes.map((item, i) => (
                        <li key={i} className="model">
                            <button className="button-size" onClick={(e) => onSetSize(e, item.width_mm, item.height_mm)}>
                                {item.height_mm ? `${item.height_mm}x` : null}{item.width_mm}
                            </button>
                        </li>
                    ))}
                </ul>
            )}

            {selectedSize && colors.length ? <h3 style={{ margin: '10px 0 0 10px' }}>Цвет:</h3> : null}
            {selectedSize && (
                <ul className="categories-model">
                    {colors.map((item, i) => (
                        <li key={i} className="model">
                            <button className="button-size" onClick={(e) => onSetColor(e, item)}>
                                {item}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
}

export default CategoriesModel;
