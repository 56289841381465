import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'; 
import useCounter from "../../../../hooks/useCounter";
import useFavourites from "../../../../hooks/useFavourites";
import ProductCounter from "../../../productsList/productCounter"; 
import './../../../StylesProduct/description.scss'


const Description = ({productGrid}) => {
    const { cartItems, handleAddToCart, handleRemoveFromCart, handleToggleCart } = useCounter();
    const { favourites, toggleFavourite } = useFavourites();

    const elem = (productGrid) => {
        const {name_of_product, annotation, main_photo_link, additional_photo_links} = productGrid;
        const regex = /(https:\/\/.*?\.jpg)/g;
        const matches = [...additional_photo_links.matchAll(regex)].map((match) => ({ url: match[1] }));
 
        const imgs = matches.map(item => {
            return(
                <div>
                    <img src={item.url} alt={name_of_product}/>
                </div>
        
            )
        }) 
        return(
            <div className="description">
            <div className="carousel-slide">
                <Carousel showStatus={false}>
                    <div>
                        <img src={main_photo_link} alt={name_of_product}/>
                    </div>
                    {imgs}
                </Carousel>
 
            </div>
            <div className="definition">
               
                <ProductCounter
                    item={productGrid} 
                /> 
               
                <div className="definition__description">
                    {annotation.replace(/&lt;br\/&gt;&lt;br\/&gt;/g, '')}
                </div>

            </div>
        </div>
        )
    }
   
    const item = productGrid ? elem(productGrid[0]) : null
    return (
       <>
         {item}
       </>
    )
}

export default Description;