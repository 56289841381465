 
import React, { useState } from "react";
import Modal from "react-modal";
import { useForm, Controller} from "react-hook-form";
import { useHttp } from "../../hooks/http.hooks"; 
import close from './../../resources/img/ico/close.svg'; 
import './modal.scss'


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "100vw",
    height: "100vh",
    borderRadius: 10,
    zIndex: 1
  },
};

function ModalConfirmation({modalOpen, setModalOpen, number, setStatusConf, url}) {
    const {loading, request,  errorMessage, clearError, successfull} = useHttp();
    
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm()
    
      const onSubmit = (data) => {  
        handleCode(data)
      } 
    //   const { loading, request, error, clearError } = useHttp();
 
    const handleCode = async (data) => { 
        data.number = number 
        try {
          const response = await request(`${process.env.REACT_APP_SERVER_URL}/verify`, "POST", JSON.stringify(data),   {'Content-Type': 'application/json'});
          window.location.href = `${url}`
          setStatusConf(true) // Обработка успешного ответа от сервера
          setModalOpen(false)
        } catch (e) {
          console.error(e); // Обработка ошибки
        }
      };
 
 
 
  return (
    <div className="modal-order">
  
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
        ariaHideApp={false}
     
      >
        <div className="auth-login">
            <form className='login' onSubmit={handleSubmit(onSubmit)}>
                <p>Код подтверждения отправлен вам по смс</p> 
    
                <input  type='number' placeholder='Код' className='code' {...register("code", { required: true, minLength: 4,maxLength: 4 })} />
            
                <span className="error">{errorMessage}</span>
                <button type="submit" >Потвердить</button> 
            </form> 
        </div>
        <button className="close-modal" onClick={() => setModalOpen(false)}>
          <img src={close} alt="close-modal"/>
        </button>
  
      </Modal>
    </div>
  );
}

export default  ModalConfirmation;