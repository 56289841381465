import './../../../StylesProduct/configuration.scss'

const Configuration = ({productBirdhouse}) => {
  
    console.log(productBirdhouse)
    return(
        <ul className='configuration'>
            <li>Кормушка - 1шт.</li>
        </ul>
    )
}

export default Configuration;