import AppHeader from "../appHeader/app-header"
import Feedback from "../feedback/feedback"
import Footer from "../footer/footer"
import { useForm } from "react-hook-form";
import useMarketService from "../../services/market-services"
import './authorization/authorization.scss'
import {useState} from "react"

const UpdatePassword = () => {
    const {updatePassword,  errorMessage} = useMarketService();
    const [state, setState] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm()
    
      const onSubmit = (data) => { 
        
        onLogin(data)
      } 

    const onLogin = async (data) => {
        updatePassword(data)
            .then(res => console.log(res))   
      }
    return(
        <div className="update-password"> 
                 <AppHeader setState={setState} state={state}/>
            <main>
                <div className='container'>
                    <div className="auth-login">
                        {errorMessage ? errorMessage :  <form className='login' onSubmit={handleSubmit(onSubmit)}>
                                <h2 className="head-login">Сброс пароля</h2>
                                <input className='number-auth' type='text' defaultValue="+7" {...register("number", {required: true, minLength: 11,maxLength: 12})} />
                                {errors.exampleRequired && <span>This field is required</span>} 
                                <p className="error">{ errorMessage}</p>
                                <button type="submit" >Сбросить пароль</button> 
                            </form> }
                    </div>
                </div>
            </main>
            <Feedback/>
            <Footer/>
        </div>
    )
}

export default UpdatePassword