 
import useFavourites from '../../../hooks/useFavourites';
import AppHeader from '../../appHeader/app-header';
import CartMenu from '../../cart-menu/cart-menu';
import Footer from '../../footer/footer';
import FavouritesProducts from '../../favouritesProducts/favourites-products';
import arrowCategories from '../../../resources/img/ico/arrow__catalog-menu.svg';
import Feedback from '../../feedback/feedback';
import { Helmet } from 'react-helmet';
import './favourites.scss'
import MobileMenu from '../../mobileMenu/mobile-menu';
import { useState, useEffect } from 'react';
import CatalogMenu from '../../catalogMenu/catalog-menu';

const Favourites = () => {
    
    const { favourites, toggleFavourite } = useFavourites();
    const [state, setState] = useState(false)
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      // Создаем медиа-запрос для экранов с максимальной шириной 768px
      const mediaQuery = window.matchMedia('(max-width: 768px)');
  
      // Функция обратного вызова, которая будет вызвана при изменении медиа-запроса
      const handleMediaQueryChange = (event) => {
        setIsMobile(event.matches);
      };
  
      // Добавляем слушатель для изменений медиа-запроса
      mediaQuery.addListener(handleMediaQueryChange);
  
      // Вызываем обработчик медиа-запроса сразу при монтировании компонента
      handleMediaQueryChange(mediaQuery);
  
      // Очищаем слушатель при размонтировании компонента
      return () => {
        mediaQuery.removeListener(handleMediaQueryChange);
      };
    }, []);
  
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Избранное</title> 
        </Helmet>
        <AppHeader setState={setState} state={state}/>
        <main>
        <div className='container'>
            <div className='row'>
                <div className='col-left__main'>
                    {isMobile ?   <CatalogMenu  setState={setState} state={state}/>  : null}
                    <h1>Избранное({favourites.length})</h1>
                    <CartMenu/>
                </div>
                <div className='col-right__main'>
                    <ul className='categories'>
                        <li>
                            <a href='/'>Главная</a>
                        </li>
                            <img src={arrowCategories} alt='arrow__catalog-menu'/>
                        <li>
                            <a href='/favourites'>Избранное</a>
                        </li>
                    </ul>
                    <FavouritesProducts favourites={favourites} toggleFavourite={toggleFavourite}/>
                </div> 
               
            </div>
            <Feedback/> 
        </div>
        </main>
        <MobileMenu/>
        <Footer/>
    </>
    )
}

export default Favourites;