import './../../../StylesProduct/configuration.scss';

const Configuration = ({ productPorches }) => {
    if (!productPorches || !productPorches.length) return null;
    const regex = /([А-Я].*?)\./g; 

    const matches = [];
    let match;

    console.log("Package contents:", productPorches[0].package_contents);

    while ((match = regex.exec(productPorches[0].package_contents)) !== null) {
        matches.push({ elem: match[1] });
    }

    console.log("Matches:", matches);

    return (
        <ul className='configuration'>
            {matches.map((item, index) => (
                <li key={index}>
                    {item.elem}
                </li>
            ))}
        </ul>
    );
};

export default Configuration;