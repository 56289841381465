import { useForm } from "react-hook-form";
import useMarketService from '../../services/market-services';
import { useState } from "react"; 


export const Login = ({user, handleAddToUser}) => {
    const {handleLogin,  errorMessage} = useMarketService();
 
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm()
    
      const onSubmit = (data) => { 
        
        onLogin(data)
      } 

    const onLogin = async (data) => {
        handleLogin(data)
          .then(res => handleAddToUser(res.userId))  
      }
    return(
        <form className='login' onSubmit={handleSubmit(onSubmit)}>
            <h2 className="head-login">Войти</h2>
            {/* register your input into the hook by invoking the "register" function */}
            <input className='number-auth' type='text' defaultValue="+7" {...register("number", {required: true, minLength: 11,maxLength: 12})} />

            {/* include validation with required or other standard HTML validation rules */}
            <input  type='password' placeholder='Пароль' className='pass-auth' {...register("password", { required: true, minLength: 6,maxLength: 20 })} />
            {/* errors will return when field validation fails  */}
            {errors.exampleRequired && <span>This field is required</span>}
            <span onClick={() => window.location.href = `/update-password`}>Забыли пароль?</span>
            <p className="error">{ errorMessage}</p>
            <button type="submit" >Войти</button> 
        </form> 
    )
}

export const Registration = ({handleAddToUser, setModalOpen, setNumber}) => {
    const {handleRegistration, errorMessage} = useMarketService();
    const [error, setError] = useState()
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm()
    
      const onSubmit = (data) => { 
     
        onRegistration(data)
      } 

   

    const onRegistration = async (data) => { 
        console.log(data)
        if(data.password === data.repPassword){
            setNumber(data.number)
            handleRegistration(data)
                .then(res => {
                    setModalOpen(true) 
                })
                .catch(e => setError(e))
           
        }
        
     };  
  
    return(
        <form className='login' onSubmit={handleSubmit(onSubmit)}>
            <h2 className="head-login">Зарегестрироваться</h2>
            {/* register your input into the hook by invoking the "register" function */}
            <input className='number-auth' type='text' defaultValue="+7" {...register("number", {required: true, minLength: 11,maxLength: 12})} />

            {/* include validation with required or other standard HTML validation rules */}
            <input  type='password' placeholder='Пароль' className='pass-auth' {...register("password", { required: true, minLength: 6,maxLength: 20 })} />
            <input  type='password' placeholder='Повторите пароль' className='pass-auth' {...register("repPassword", { required: true, minLength: 6,maxLength: 20 })} />
            {/* errors will return when field validation fails  */}
            {errors.exampleRequired && <span>This field is required</span>}
            {/* {error ? error : null} */}
            <p className="error">{ errorMessage}</p>
            <button type="submit" >Зарегестрироваться</button> 
        </form> 
    )
}