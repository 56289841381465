import { useState, useEffect } from 'react';
import SearchResult from './search-result';
import useMarketService from '../../services/market-services';
import './search-header.scss'
 

const SearchHeader = () => {
    const {getProducts} = useMarketService();
    const [products, setProducts] = useState(false);
    const [result, setResult] = useState();
    const [searchInput, setSearchInput] = useState(false)
    useEffect(()=> {
        getProducts('allproducts').then(setProducts)
    }, [])
    const onGetProducts = (search) => {  
        search ? setSearchInput(true) : setSearchInput(false) 
          setResult(products.filter(product =>
            product.name_of_product.toLowerCase().includes(search.toLowerCase())
          ))
    } 
    return (
       <>
            <input type='text'  placeholder='Поиск по сайту' className='search-input'  onChange={(e) => onGetProducts(e.target.value)}/>
            <SearchResult result={result} searchInput={searchInput}/>
       </>
    )
}

export default SearchHeader;