import AppHeader from '../../appHeader/app-header';
import AppHeaderMainPage from '../../appHeaderMainPage/app-header-main-page';
import Footer from '../../footer/footer';
import { useState, useEffect } from 'react'; 
import ModalConfirmation from '../../modal/modalConfirmation';
import { Navigate } from "react-router-dom";
import './authorization.scss'
import Feedback from '../../feedback/feedback';
import { Login, Registration } from '../../login/login';
import MobileMenu from '../../mobileMenu/mobile-menu';
import CatalogMenu from '../../catalogMenu/catalog-menu';


const Authorization = ({useUser}) => {
  const { user, handleAddToUser} = useUser()
  const [state, setState] = useState(false)
      const [modalOpen, setModalOpen] = useState(false)
      const [number, setNumber] = useState()
      const [isMobile, setIsMobile] = useState(false);
    
      useEffect(() => {
          // Создаем медиа-запрос для экранов с максимальной шириной 768px
          const mediaQuery = window.matchMedia('(max-width: 768px)');
      
          // Функция обратного вызова, которая будет вызвана при изменении медиа-запроса
          const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
          };
      
          // Добавляем слушатель для изменений медиа-запроса
          mediaQuery.addListener(handleMediaQueryChange);
      
          // Вызываем обработчик медиа-запроса сразу при монтировании компонента
          handleMediaQueryChange(mediaQuery);
      
          // Очищаем слушатель при размонтировании компонента
          return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
          };
        }, []);
  
      if (user) {
        return <Navigate to="/me" replace={true} />;
      }
     
    return(
        <div className='authorization'>
            <AppHeaderMainPage/>
            <AppHeader setState={setState} state={state}/>
            <div className="container"> 
              {isMobile ?   <CatalogMenu  setState={setState} state={state}/>  : null}
                <div className="auth-login">
                  <Login user={user} handleAddToUser={handleAddToUser}  />
                  <Registration handleAddToUser={handleAddToUser} setModalOpen={setModalOpen} setNumber={setNumber}/>
                </div>
                <ModalConfirmation modalOpen={modalOpen} setModalOpen={setModalOpen} number={number}/>
            </div>
            <Feedback/>
            <MobileMenu/>
            <Footer/>
        </div>
    )
}

export default Authorization;