 
import './../../../StylesProduct/configuration.scss'
const Configuration = ({productAntiTheft}) => {
  
  
 
    return(
        <ul className='configuration'>

            <li>
                шкаф - 1 шт; 
            </li>
            <li>
                лестница - 1 шт;
            </li>
            <li>
                крюки для вертикального навеса лестницы - 2 шт;
            </li>
            <li>
                крюки для горизонтального навеса лестницы - 2 шт;
            </li>
            <li>
                паспорт - 1 экз; 
            </li>
        </ul>   
    )
}

export default Configuration;