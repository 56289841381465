import './about.scss'

const About = () => {
    return(
        <div className='about'>
            <h1>Интернет магазин АрсеналЪ</h1>
            <span>
                <br/>
                 Добро пожаловать в наш интернет-магазин, где мы предлагаем широкий ассортимент разнообразных продуктов для вашего удобства и безопасности. Мы специализируемся на различных и уникальных решениях, которые обеспечивают защиту, функциональность и стиль в вашем доме и саду.
                 <br/>
                 <br/>
                 Наш ассортимент включает в себя универсальные раздвижные решетки для окон и дверей, которые обеспечивают надежную защиту от несанкционированного доступа, сохраняя при этом эстетическую привлекательность вашего дома. Мы также предлагаем защитные козырьки и корзины для обеспечения долговечности и эффективности работы вашего кондиционера.
                 <br/><br/>
                 Для тех, кто заботится о безопасности своего автомобильного прицепа, мы предлагаем несколько видов противоугонных устройств, которые обеспечивают надежную защиту о от несанкционированного снятия прицепа с фаркопа, так и от сцепки с чужой машиной отдельно стоящего прицепа.
                 <br/><br/>
                 Если вы ищете крепежные перголы для беседок или уличных качелей, то мы готовы предложить вам надежные и элегантные решения для вашего удовольствия и комфорта. Кроме того, у нас вы можете найти крепежные системы для дровниц, которые обеспечивают прочность и устойчивость при хранении дров.
                 <br/><br/>
                 Мы также предлагаем очаровательные кормушки для птиц в современном минимализме, которые превращаются в знакомые формы и отлично вписываются в ландшафт вашего сада. Эти кормушки идеально подходят для привлечения птиц и позволят вам и вашим детям наслаждаться наблюдением за жизнью пернатых друзей.
                 <br/><br/>
                 В нашем магазине мы также предлагаем флагштоки для крепления флагов, которые позволят вам выразить свою патриотическую приверженность или украсить свою территорию уникальными символами.
                 <br/><br/>
                 Каждый квартал мы добавляем новую продукцию. Кроме того, мы готовы принимать заказы на изготовление металоконструкций согласно ваших индивидуальных потребностей и требований.
                 <br/><br/>
                 Приобретая товары у нас, вы можете быть уверены в их качестве, надежности и долговечности. Наш интернет-магазин стремится предоставить вам лучший сервис, удобство покупок и полное удовлетворение ваших потребностей.
            </span>
        </div>
    )
}

export default About;