import AppHeaderMainPage from "../appHeaderMainPage/app-header-main-page";
import AppHeader from "../appHeader/app-header";
import { Link } from "react-router-dom";
import Feedback from "../feedback/feedback";
import Footer from "../footer/footer";
import { useEffect } from "react";
import useCounter from "../../hooks/useCounter";
import {useState} from "react"

const OrderPaid = () => {
    const {removeAllCart } = useCounter();
    const [state, setState] = useState(false)
    useEffect(()=> {
        removeAllCart()
    },[])

    return(
        <div className="order-paid">
            <AppHeaderMainPage/>
            <AppHeader setState={setState} state={state}/>
            <main>
                <div className='container'>
                    <div className="order-created">
                        <h1>Ваш заказ создан</h1>
                        <p>Узнать о статусе можно в <Link to={'/orders'}>личном кабинете</Link></p>
                    </div>
                </div>
            </main>
            <Feedback/>
            <Footer/>
        </div>
    )   
}

export default OrderPaid;