 
import './../../../StylesProduct/configuration.scss'
const Configuration = ({productAntiTheft}) => {
  
  
 
    return(
        <ul className='configuration'>

            <li>
                Противоугонное устройство - 1 шт.
            </li>
            <li>
                Проушина - 1 шт.
            </li>
        </ul>
    )
}

export default Configuration;