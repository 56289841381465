import './../../../StylesProduct/specifications.scss'

const Specifications = ({productFireCabinets}) => {
    const {
        anti_theft_device_type, product_color, shape, 
        feedback, warranty, 
            type, purpose,st_num, 
            material, country_of_manufacture
          } = productFireCabinets[0]
          console.log(productFireCabinets[0])
    return(
        <div className="specifications">
            <h2>Характеристики</h2>
            <ul> 
                <li>
                    <span>Тип</span>
                    <span>{type}</span>
                </li>
                <li>
                    <span>Цвет</span>
                    <span>{product_color}</span>
                </li>

                <li>
                    <span>Форма </span>
                    <span>{shape}</span>
                </li> 
                <li>
                    <span>Гарантия</span>
                    <span>{warranty}</span>
                </li>
                <li>
                    <span>Страна производитель</span>
                    <span>{country_of_manufacture}</span>
                </li>
            </ul>
        </div>
    )
}

export default Specifications;