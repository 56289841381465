import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useState } from "react";
import { Helmet } from 'react-helmet';
import ProductCounter from "../../../productsList/productCounter";
import ModalCorusel from "../../../modal/modalCorusel";
import './../../../StylesProduct/description.scss'



const Description = ({productAntiTheft}) => {
    const [modalOpen, setModalOpen] = useState(false) 
    const elem = (productAntiTheft) => {
        const {name_of_product, annotation, price_rubles, 
            main_photo_link, additional_photo_links, vehicle_make, device_type, article} = productAntiTheft;
        // ЯндексМетрика - Электронная комерция 
            // Просмотр карточки товара
            const ecommerceObject = {
                "ecommerce": {
                    "currencyCode": "RUB",
                    "detail": {
                        "products": [
                            {
                                "id": article,
                                "name" : name_of_product,
                                "price": price_rubles,
                                "brand": "АрсеналЪ",
                                "category": "Противоугонные устройства",
                                "variant" : "Стандарт/Усиленное"
                            }
                        ]
                    }
                }
            }
            window.dataLayer.push(ecommerceObject);
            // Добавление карточки в корзину


        const regex = /(https:\/\/.*?\.jpg)/g;
        const matches = [...additional_photo_links.matchAll(regex)].map((match) => ({ url: match[1] }));
 
        const imgs = matches.map((item,i) => {
            return(
                <div key={i} onClick={() =>setModalOpen(true)}>
                    <img src={item.url} alt={name_of_product} />
                </div> 
            )
        })
 
        return(
            <div className="description">
                <div className="carousel-slide">
                    <Carousel showStatus={false}>
                        <div >
                            <img src={main_photo_link} alt={name_of_product} />
                        </div>
                        {imgs}
                    </Carousel> 
                </div>
                <div className="definition"> 
                    <ProductCounter
                        item={productAntiTheft} 
                    />
                    <div className="definition__description">
                        <div>{annotation}</div>
                        <div className="supports-brend"><span>Марка ТС:</span>{vehicle_make}</div>
                        <div className="supports-brend"><span>Вид техники:</span>{device_type}</div>
                    </div>

                </div>
                <ModalCorusel modalOpen={modalOpen} setModalOpen={setModalOpen}/>
            </div>
        )
    }
   
    const item = productAntiTheft ? elem(productAntiTheft[0]) : null
    return (
       <>
        <Helmet>
            <script type="text/javascript">
            {`
                (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                m[i].l=1*new Date();
                for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                ym(93888307, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                ecommerce:"dataLayer"

                });
                window.dataLayer = window.dataLayer || [];
            `}
            </script>
        </Helmet>
         {item}
         
       </>
    )
}

export default Description;