import './../../../StylesProduct/specifications.scss'

const Specifications = ({productMemory}) => {
    console.log(productMemory)
    const {
        dimensions_mm, weight_in_packaging_g, product_color, height_mm, length_mm, width_mm,
        quantity_in_packaging, warranty_period, 
        keywords, purpose,st_num, 
            material, country_of_manufacture
          } = productMemory[0]
 
    return(
        <div className="specifications">
            <h2>Характеристики</h2>
            <ul>
            

                <li>
                    <span>Вес</span>
                    <span>{weight_in_packaging_g} г</span>
                </li>
                <li>
                    <span>Цвет</span>
                    <span>{product_color}</span>
                </li>
                <li>
                    <span>Вид религии</span>
                    <span>{keywords}</span>
                </li>
                <li>
                    <span>Материал</span>
                    <span>{material}</span>
                </li> 
                <li>
                    <span>Гарантия</span>
                    <span>1 год</span>
                </li>
                <li>
                    <span>Страна производитель</span>
                    <span>Россия</span>
                </li>
            </ul>
        </div>
    )
}

export default Specifications;