import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'; 
import ProductCounter from "../../../productsList/productCounter"; 
import './../../../StylesProduct/description.scss'


const Description = ({productWoodcutter}) => { 
    const elem = (productWoodcutter) => {
        const {name_of_product, annotation,
            main_photo_link, additional_photo_links} = productWoodcutter;
        
        const regex = /(https:\/\/.*?\.jpg)/g;
        const matches = [...additional_photo_links.matchAll(regex)].map((match) => ({ url: match[1] }));
 
        const imgs = matches.map((item,i) => {
            return(
                <div key={i}>
                    <img src={item.url} alt={name_of_product}/>
                </div>
            )
        }) 
        return(
            <div className="description">
                <div className="carousel-slide">
                    <Carousel showStatus={false}>
                        <div>
                            <img src={main_photo_link} alt={name_of_product}/>
                        </div>
                        {imgs}
                    </Carousel>
 
                </div>
                <div className="definition"> 
                    <ProductCounter
                        item={productWoodcutter} 
                    /> 
                    <div className="definition__description">
                        <div>{annotation}</div>
                    </div> 
                </div>
            </div>
        )
    }
   
    const item = productWoodcutter ? elem(productWoodcutter[0]) : null
    return (
       <>
         {item}
       </>
    )
}

export default Description;