import React, { useState } from "react";
import Modal from "react-modal";
import useMarketService from "../../services/market-services";
import close from './../../resources/img/ico/close.svg';
import ModalConfirmation from "./modalConfirmation"; 
import { useHttp } from "../../hooks/http.hooks";
import './modal.scss'
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';  



const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: 500,
    height: 120,
    borderRadius: 10 
  },
};

function ModalOrder({product, address, removeAllCart, useUser, totalSum, isCheckedOnline}) { 
  const {user, handleAddToUser} = useUser() 
  const [resOrders, setResOrders] = useState('')   
  const {newOrder, handleSendAuth,  error} = useMarketService();
  const [modalOpen, setModalOpen] = useState(false); 
  const [modalConf, setModalConf] = useState(false); 
  const[ statusConf, setStatusConf] = useState(false)
  const[ orderStatus, setOrderStatus] = useState(false)
  const [url, setUrl] = useState('');
  const id = uuidv4()
    const res = {
        successfullNewUser: {
            h4: "Ваш заказ создан",
            span: "Вы можете наблюдать за статусом на странице заказов. Логин и пароль для входа в ЛК отправлен вам по смс"
        },
        successfullOldUser: {
          h4: "Ваш заказ создан",
          span: "Вы можете наблюдать за статусом на странице заказов. "
        },
        error: {
            h4: "Что-то пошло не так..",
            span: "Свяжитесь с нами по номеру +7921-933-72-14"
        },
        numberExist: {
          h4: "Указанный номер уже зарегестрирован",
          a: "Авторизируйтесь"
        },
        noAddress: {
            h4: "Вы не указали пункт выдачи!",
            span: "Укажите пункт выдачи."
        }, 
        noNumber: {
            h4: "Вы не указали данные получателя!",
            span: "Укажите данные получателя."
        },
        noBaskets: {
            h4: "Корзина пуста!",
            span: "Что бы оформить заказ, положите товары в корзину."
        }
    }
      
 // ЯндексМетрика
    const ecommerceObjectBuy = {
      "ecommerce": {
          "currencyCode": "RUB",
          "purchase": {
              "actionField": {
                  "id" : "Cart"
              },
              "products": product.map(item => {
                const {article, name_of_product, price_rubles, quantity} = item
                return {
                      "id": article,
                      "name": name_of_product,
                      "price": price_rubles,
                      "brand": "АрсеналЪ",
                      "category": name_of_product,
                      "variant": name_of_product,
                      "quantity": quantity
                  } 
              
              })
          }
      }
  }
 
  useEffect(() =>{
    if (statusConf){
      const data = {
        number: address.number
      }
      handleSendAuth(data).then(res => {
        setResOrders(res.successfullNewUser)
        setModalOpen(true)
        removeAllCart()
      })
    }
  }, [statusConf]) 
  const onSubmit = () =>{  
    if (product.length > 0 && address.addressPost && address.number){
        address.userId = user
        address.total_sum = totalSum    
        address.product = product.map(item => {
            return {
                    article: item.article,
                    name_of_product: item.name_of_product,
                    price_rubles: item.price_rubles,
                    quantity: item.quantity 
                }
        }) 
        // Если пользователь авторизован, то отправляем его на оплату
        if(user){
          newOrder('new-orderauth', address).then(data => { 
            console.log(data)
            window.dataLayer.push(ecommerceObjectBuy)  
            isCheckedOnline ? window.location.href = `${data.confirmation.confirmation_url}` : window.location.href = `/order-paid`
          })
          .catch(e =>  setResOrders({h4: "Произошла ошибка",  span: e.message}))
        }
        // Если пользователь неавторизован, то регистрируем его на сайте и отправляем его на форму подтверждения смс
        else{
          newOrder('new-order', address).then(data => {
            setUrl(data.confirmation.confirmation_url)
            window.dataLayer.push(ecommerceObjectBuy)  
            setModalConf(true) 
            
          })
          .catch(e =>  {  
            setResOrders({h4: "Произошла ошибка",  span: e.message})})
            setModalOpen(true)
        } 
    }else if(!address.number){
        setResOrders(res.noNumber)
        setModalOpen(true)
    }else if(!address.addressPost){
      setResOrders(res.noAddress)
      setModalOpen(true)
    }else{
        setResOrders(res.noBaskets)
        setModalOpen(true)
    }

 
  }

  
 
  return (
    <div className="modal-order">
        <button className="get-order" onClick={() => {
    
          onSubmit()  
          }}>Оформить заказ</button>
         <ModalConfirmation modalOpen={modalConf} setModalOpen={setModalConf} number={address.number} setStatusConf={setStatusConf} url={url}/>
         <Modal
        isOpen={modalOpen}
        onRequestClose={() => {
                    setModalOpen(false)
                    if(orderStatus) window.location.href = `/orders`;
                  }}
        style={customStyles}
        ariaHideApp={false}
     
      >
        <h4>{resOrders.h4}</h4>
        <span>{resOrders.span}</span>
        {resOrders.a ? <Link to='/authorization'>{resOrders.a}</Link> : null}
        <button className="close-modal" onClick={() => {
          setModalOpen(false)
          if(orderStatus) window.location.href = `/orders`;
          }}>
          <img src={close} alt="close-modal"/>
        </button>
      </Modal>
    </div>

    
  );
}




export default ModalOrder;