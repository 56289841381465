import { useEffect } from 'react';
import orderIco from './../../resources/img/ico/order-ico.svg'
import payIco from './../../resources/img/ico/pay-ico.svg'
import deliveryIco from './../../resources/img/ico/delivery-ico.svg'
import refundIco from './../../resources/img/ico/refund-ico.svg'
import line from './../../resources/img/ico/line-info-ico.svg'
import pickup from './../../resources/img/ico/pickup.svg'
import './information-item.scss'

const InformationItem = () =>{
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'auto' // для плавной прокрутки, можно убрать для мгновенной
        });
      }, []);
    return (
        <div className="information-item">
            <div className='information-item__picter'>
                <img src={orderIco} className='info-ico' alt="order-ico" />
                <img src={payIco} className='pay-ico' alt="pay-ico" />
                <img src={deliveryIco} className='delivery-ico' alt="delivery-ico" />
                <img src={pickup} alt="pickup-ico" className='pickup-order'/>
                <img src={refundIco} className='refund-ico' alt="refund-ico" />
                <img src={line} alt="line-ico" className='line-order'/>
          
            </div>
            <div className="information-item__text">
                <h2>Как сделать заказ?</h2>
                <p>
                Для того, чтобы оформить заказ, Вам необходимо совершить несколько простых действий:
                </p>

                <h3>1. Выбираем товары</h3>
                <p>
                Последовательно выберите интересующую Вас группу товаров, например, РЕШЕТКИ. Для Вас откроется страница, где представлены товары с указанием цены, а также фотографии, эскизы, чертежи и характеристики товара.
                </p>

                <h3>2. Кладем товары в корзину</h3>
                <p>
                Выбрав понравившуюся модель, нажмите кнопку «В корзину». Товар автоматически отправится в Вашу корзину. Вы можете положить в свою корзину любое количество товаров.
                </p>

                <h3>3. Ваша корзина</h3>
                <p>
                Для оформления заказа перейдите в раздел «Корзина». Просмотрите ее содержимое. Вы можете изменить количество товара в корзине или удалить то, что Вам не нужно.
                </p>

                <h3>4. Оформляем заказ</h3>
                <p>
                Для завершения оформления заказа Вам необходимо нажать на кнопку «Оформить заказ», ввести свои контактные данные. После нажатия кнопки "Оформить заказ", он будет передан для оформления нашему менеджеру, после чего он сам Вам перезвонит на указанный номер и уточнит все детали заказа.
                <br/>
                Вы также можете самостоятельно связаться с менеджером для оформления заказа по телефону: +7-921-933-72-14 (WhatsApp).
                </p>

                <h2>Как оплатить заказ?</h2>
                <p>
                Способы оплаты для физических и юридических лиц:
                </p>

                <p>
                <strong>Для физических лиц:</strong>
                <br/>
                - Наличный расчет в офисе по адресу: СПб, Большой Сампсониевский пр., д. 80, лит. А, пом. 8 Н с 10 до 19, кроме выходных.
                <br/>
                - On-line оплата на сайте интернет-магазина.
                </p>

                <p>
                <strong>Для юридических лиц:</strong>
                <br/>
                - Безналичная оплата по счёту.
                </p>

                <h2>Доставка</h2>
                <p>
                Способы доставки, расчёт стоимости и срока доставки:
                </p>

                <p>
                Уважаемые покупатели, наш интернет-магазин предлагает осуществить доставку выбранных вами товаров, воспользовавшись услугой транспортной компании СДЭК. Ориентировочную стоимость и срок доставки можно рассчитать на сайте интернет-магазина при оформлении заказа. Окончательная стоимость и срок доставки будут известны при передаче товара к отправке в СДЭК. Покупателю отправляются только полностью оплаченные товары. Стоимость доставки оплачивается отдельно при получении товара в пункте выдачи СДЭК.
                </p>

                <h2>Самовывоз</h2> 

                <p>
                Наш интернет-магазин предлагает вам возможность забрать выбранные товары самостоятельно. <br/> Забрать заказ можно по адресу: г. Санкт-Петербург, ул. Лисичанская, д. 5 с 10:00 до 18:00. Оформить самовывоз можно на сайте интернет-магазина при оформлении заказа. Товар для самовывоза будет готов только после полной оплаты и подтверждения заказа. Пожалуйста, дождитесь уведомления о том, что ваш заказ готов к выдаче, прежде чем приезжать за ним.
                </p>

                <h2>Возврат товара </h2>
                <p>Покупатель вправе отказаться от заказа только до момента его получения.  В случае обнаружения брака или каких-либо производственных дефектов в товаре возможен его обмен на аналогичный товар надлежащего качества. 
                Возврат и обмен Товара НАДЛЕЖАЩЕГО качества, производится по желанию Покупателя в связи с не устраивающими его свойствами (несоответствие формы, габаритов, фасона, расцветки, размера.), не относящегося к Товару, не подлежащему обмену или возврату или имеющему индивидуально-определенные свойства. Возврат производится в течение 7 (семи) дней, обмен производится в течение 14 (четырнадцати) дней после его получения, по письменному заявлению Покупателя, после осмотра техническим специалистом, без следов установки, в оригинальной упаковке, при условии компенсации Покупателем транспортных (и иных видов) расходов, связанных с  доставкой Заказа Покупателю. Возврат такого Товара осуществляется за счет Покупателя. Возврат и обмен Товара НАДЛЕЖАЩЕГО качества не распространяется на товар, оформленный в разделе «Под заказ».    </p>
                <p>Возврат и обмен товара НЕ НАДЛЕЖАЩЕГО качества производится в течение гарантийного срока по письменному заявлению Покупателя, после проведения экспертизы на предмет установления причин выхода из строя или выявленных недостатков. Срок на проведение экспертизы и ответ Покупателю на предъявленную им Претензию устанавливается в 30 рабочих дней. Возврат и обмен возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара (товарная накладная).   </p>
                <p>Срок возврата денежных средств составляет 10 рабочих дней с момента возврата Товара (в случае возврата Товара надлежащего качества) на склад Продавца, либо с момента заключения экспертизы о признании товара некачественным или дефектным не по вине Покупателя.  </p>
                <p>Возврат Продавцом денежных средств Покупателю осуществляется тем же способом, каким Покупатель произвел оплату Продавцу.   </p>

            </div>
        </div>
    )
}

export default InformationItem;