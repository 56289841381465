import { useEffect } from 'react';
import useMarketService from '../../../services/market-services';
import { useState } from 'react';
import { saveAs } from 'file-saver'; 

 



function AdsGaneratorAvito() {
  const[gridsOne, setGridsOne] = useState([])
  const {getProducts, uploadFile} = useMarketService()

  useEffect(()=>{
    getProducts('grids-one').then(setGridsOne)
  }, [])

  const gridsOneXml = gridsOne.map(grid => {
    const{article, name_of_product, annotation, main_photo_link,price_rubles } = grid

    return(
      `
        <Ad>
          <Id>${article}</Id>
          <AdStatus>Free</AdStatus>
          <AllowEmail>Нет</AllowEmail>
          <Address>Санкт-Петербург, Лиговский проспект, 1</Address>
          <ContactPhone>+7 921 933-72-14</ContactPhone>
          <Category>Ремонт и строительство</Category>
          <Condition>Новое</Condition>
          <GoodsType>Окна и балконы</GoodsType> 
          <AdType>Товар от производителя</AdType>
          <Title>${name_of_product}</Title>
          <Description>${annotation}</Description>
          <Price>${price_rubles}</Price>
          <Images>
            <Image url="${main_photo_link}"/> 
          </Images>
        </Ad> 
      `
    )
  })
 

  const allGridsXml =         `
  <Ads formatVersion="3" target="Avito.ru">
    ${gridsOneXml}
  </Ads>
  `
  
  const handleDownload = () => {
    const blob = new Blob([allGridsXml], { type: 'text/xml' });
    saveAs(blob, 'table.xml');
  };

  const collectUpload = () => {
    // const blob = new Blob([allGridsXml], { type: 'text/xml' });
    // const formData = new FormData();
    // const head = {
    //   'Content-Type': 'text/xml',
    //   'Content-Disposition': 'attachment; filename="grids-one.xml"',
    // }
    // formData.append('file', blob, 'grids-one.xml');
    const data = { fileName: 'grids-one.xml', xmlData: allGridsXml }
    uploadFile(data)
  }
   

  return (
    <div className="App">
       <button onClick={collectUpload}>Установить XML файл</button> 
    </div>
  );
}

export default AdsGaneratorAvito;
