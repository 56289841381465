 
import React, { useState } from 'react';
import useCounter from '../../hooks/useCounter';
import useFavourites from '../../hooks/useFavourites';
import openLink from './openLink'; 
import ProductCounter from './productCounter';
import Categories from './categories';
import { Col, Row } from 'antd';
import './products-list.scss';
import CategoriesModel from './categoriesModel';

const ProductsList = ({ product, originalProduct, setProduct }) => { 
  const [visibleProducts, setVisibleProducts] = useState(9);
  const { cartItems, handleAddToCart, handleRemoveFromCart, handleToggleCart } = useCounter();
  const [sortOption, setSortOption] = useState('');
  const { favourites, toggleFavourite } = useFavourites();
  
 
  const showMoreProducts = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 9);
  };

  const handleSortChange = (event) => {
    const selectedSortOption = event.target.value;
    setSortOption(selectedSortOption);
  };

  const getSortedProducts = () => {
    let sortedProducts = [...product];

    if (sortOption === 'priceAsc') {
      sortedProducts.sort((a, b) => a.price_rubles - b.price_rubles);
    } else if (sortOption === 'priceDesc') {
      sortedProducts.sort((a, b) => b.price_rubles - a.price_rubles);
    } else if (sortOption === 'nameAsc') {
      sortedProducts.sort((a, b) => a.name_of_product.localeCompare(b.name_of_product));
    }

    return sortedProducts;
  };

  const sortedProducts = getSortedProducts();

  const visibleSortedProducts = sortedProducts.slice(0, visibleProducts);

  const elemProducts = visibleSortedProducts.map((item) => {
        const {
          annotation,
          article,
          name_of_product,
          main_photo_link,
          price_rubles,
          price_before_discount 
        } = item;
     
        const handleToggleFavourite = () => {
          toggleFavourite(item);
        };
        const isFavourite = (elem) => {return elem.some((favourite) => favourite.article === item.article)}

        return (
   
          <li key={article} className="product">
            <img src={main_photo_link} alt={name_of_product} onClick={() => openLink(item)} />
            <div className="about-product">
              <h2 onClick={() => openLink(item)}>{name_of_product}</h2>
              <div className="text__about-product" onClick={() => openLink(item)}>
                {annotation}
                <div></div>
              </div>
            </div>
            <div className='panel-product'>
            
                   
                    <ProductCounter
                      item={item} 
                    />
                         
                      
            </div>
          </li>
        );
  });

  const showMoreButton =
    product && visibleProducts < sortedProducts.length ? (
      <button className="show-more__btn" onClick={showMoreProducts}>
        Показать еще
      </button>
    ) : null; 
  return (
    <div className="products-list">
      <Categories categories={product[0] ? product[0].categories : null} />
      <div className="sort_catalog-pages-head">
        <div>
         
          <select value={sortOption} onChange={handleSortChange}>
            <option value="">Выберите фильтр</option>
            <option value="priceAsc">По возрастанию</option>
            <option value="priceDesc">По убыванию</option>
            <option value="nameAsc">По наименованию</option> 
          </select> 
        </div>
        {/* <div>
          По популярности
          <img src={arrowSort} alt="arrow-sort" />
        </div> */}
      </div>
      {product.length > 289 ? null : <CategoriesModel product={product} originalProduct={originalProduct} setProduct={setProduct}/>}
      
      <p className='market-place__links' style={{fontSize: '16px'}}  onClick={() => {window.open('https://www.ozon.ru/seller/metal-design-2003701/products/?miniapp=seller_2003701', '_blank', 'noopener,noreferrer');}}>Мы есть на <span style={{color: '#005bff', fontWeight: 'bold'}}>OZON</span> {'  '}
                                        <img style={{width: '20px' }} src='https://static.tildacdn.com/tild3738-6536-4137-b663-346266376533/trophy-04.png'/> 4.9</p>
 
      <ul className="products">{elemProducts}</ul>
      {showMoreButton}
    
    </div>
  );
};

export default ProductsList;
