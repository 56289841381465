import { Helmet } from "react-helmet";
import AppHeader from "../appHeader/app-header";
import CartMenu from "../cart-menu/cart-menu";
import Feedback from "../feedback/feedback";
import Footer from "../footer/footer";
import InformationItem from "../informationItem/information-item";
import MobileMenu from "../mobileMenu/mobile-menu";
import CatalogMenu from "../catalogMenu/catalog-menu";
import { useState, useEffect } from "react";


const Information = () => { 
  const [isMobile, setIsMobile] = useState(false);
  const [state, setState] = useState(false)

  useEffect(() => {
    // Создаем медиа-запрос для экранов с максимальной шириной 768px
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    // Функция обратного вызова, которая будет вызвана при изменении медиа-запроса
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    // Добавляем слушатель для изменений медиа-запроса
    mediaQuery.addListener(handleMediaQueryChange);

    // Вызываем обработчик медиа-запроса сразу при монтировании компонента
    handleMediaQueryChange(mediaQuery);

    // Очищаем слушатель при размонтировании компонента
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

    return(
        <div className="information">

 
          <Helmet>
            <meta charSet="utf-8" />
            <title>АрсеналЪ - Интернет магазин</title> 
          </Helmet> 
          <AppHeader setState={setState} state={state}/>
          <main>
            <div className='container'>
                <div className='row'>
                    <div className='col-left__main'>
                    {isMobile ?   <CatalogMenu  setState={setState} state={state}/>  : null}
                    <h1>Информация</h1>
                    <CartMenu/> 
                    
                  </div>
                  <div className='col-right__main'>
                        <InformationItem/>
                
                  </div>
                </div>
    
            </div> 
              
                <Feedback />
          
          </main>
          <MobileMenu/>
          <Footer/>
        
        </div>
    )
}

export default Information;