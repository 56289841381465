import { Helmet } from "react-helmet";
import AppHeader from "../appHeader/app-header";
import Feedback from "../feedback/feedback";
import Footer from "../footer/footer"; 

const Offer = () => {
 
    return(
        <div className="offerta">
                  

 
                <Helmet>
                <meta charSet="utf-8" />
                <title>АрсеналЪ - Интернет магазин</title> 
                </Helmet> 
                <AppHeader/>
                <main>
                <div className='container'>
                <h1 style={{width: '100%'}}>ПУБЛИЧНЫЙ ДОГОВОР  ОФЕРТА ИНТЕРНЕТМАГАЗИНА Арсеналъ Металлоконструкция</h1>
                    <p><strong>&nbsp;</strong></p>
                    <p>&nbsp;Настоящий договор между интернет-магазином &laquo;Арсеналъ Металлоконструкция&raquo; и пользователем услуг интернет-магазина, именуемым в дальнейшем &laquo;Покупатель&raquo; определяет условия приобретения товаров через сайт интернет-магазина АрсеналЪ-1.рф</p>
                    <p>&nbsp;</p>
                    <h2>1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                    <p>1.1. ООО <strong>&laquo;</strong>Арсеналъ Металлоконструкция&raquo; публикует настоящий договор купли-продажи, являющийся публичным договором - офертой (предложением) в адрес физических и юридических лиц в соответствии со ст. 435 и пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации (далее - ГК РФ).</p>
                    <p>1.2. Настоящая публичная оферта (именуемая в дальнейшем &laquo;Оферта&raquo;) определяет все существенные условия договора между ООО <strong>&laquo;</strong>Арсеналъ Металлоконструкция&raquo; и лицом, акцептовавшим Оферту.</p>
                    <p>1.3. Настоящий договор заключается между Покупателем и интернет-магазином в момент оформления заказа.</p>
                    <p>1.4. Оферта может быть акцептована (принята) любым физическим или юридическим лицом на территории РФ, имеющим намерение приобрести товары, предоставляемые ООО <strong>&laquo;</strong>Арсеналъ Металлоконструкция&raquo; через интернет-магазин, расположенный на сайте АрсеналЪ-1.рф</p>
                    <p>1.5. Покупатель безоговорочно принимает все условия, содержащиеся в оферте в целом (т.е. в полном объеме и без исключений).</p>
                    <p>1.6. В случае принятия условий настоящего договора (т.е. публичной оферты интернет-магазина), физическое или юридическое лицо, производящее акцепт оферты, становится Покупателем.</p>
                    <p>Акцептом является факт оплаты заказа в размере и на условиях настоящего соглашения.</p>
                    <p>1.7. Оферта, все приложения к ней, а также вся дополнительная информация о товарах, опубликована на сайте АрсеналЪ-1.рф <a href="арсеналъ-1.рф">&nbsp;</a></p>
                    <p>&nbsp;</p>
                    <h2>2. СТАТУС ИНТЕРНЕТ &ndash; МАГАЗИНА АрсеналЪ-1.рф&nbsp;</h2>
                    <p>2.1. Интернет-магазин является собственностью ООО <strong>&laquo;</strong>Арсеналъ Металлоконструкция&raquo; и предназначен для организации дистанционного способа продажи товаров через сеть интернет.</p>
                    <p>2.2. Интернет-магазин не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении заказа.</p>
                    <p>&nbsp;</p>
                    <h2>3. СТАТУС ПОКУПАТЕЛЯ</h2>
                    <p>3.1. Покупатель несет ответственность за достоверность предоставленной при оформлении заказа информации и ее чистоту от претензий третьих лиц.</p>
                    <p>3.2. Покупатель настоящим подтверждает, что он ознакомлен с информацией о выбранном товаре, его потребительских свойствах, гарантийных сроках, правилами и условиями эффективного и безопасного использования, сведениями о необходимых действиях по истечению срока годности Товара. Подтверждением того, что Покупатель ознакомлен с информацией о товаре указанной в п. 3.2. является сформированный Покупателем&nbsp; заказ на сайте АрсеналЪ-1.рф <a href="арсеналъ-1.рф">&nbsp;</a></p>
                    <p>3.3. Покупатель настоящим подтверждает, что он ознакомлен с информацией о Продавце:&nbsp; (наименование, адрес местонахождения, режим работы, государственной регистрация&nbsp; размещенной внизу страницы сайта &nbsp;АрсеналЪ-1.рф <a href="арсеналъ-1.рф">&nbsp;</a></p>
                    <p>3.4. Использование ресурса интернет-магазина для просмотра и выбора товара, а также для оформления заказа является для Покупателя безвозмездным.</p>
                    <h2>4. ПРЕДМЕТ ОФЕРТЫ</h2>
                    <p>4.1. Продавец, на основании заказов Покупателя и на основании предварительной оплаты, продаёт Покупателю товар в соответствии с условиями и по ценам, установленным Продавцом в оферте и приложениях к ней.</p>
                    <p>4.2. Доставка товаров, заказанных и оплаченных Покупателем, осуществляется Продавцом или Перевозчиком. Покупатель имеет право забрать товар со склада Продавца самостоятельно. Покупателю при оформлении заказа предоставляется право выбора способа доставки.</p>
                    <p>4.3. Физическое или юридическое лицо считается принявшим все условия оферты (акцепт оферты) и приложений к ней в полном объеме и без исключений с момента поступления денежных средств в счет оплаты товара на расчётный счёт Продавца (в случае безналичной оплаты), в кассу Продавца (в случае оплаты наличными) либо с момента поступления денежных средств в счет оплаты товара на расчетный счет Оператора платежной системы (в случае оплаты через платежные системы). В случае акцепта оферты одним из вышеуказанных способов, физическое лицо считается заключившим с Продавцом договор купли-продажи заказанных товаров и приобретает статус Покупателя.</p>
                    <p>&nbsp;</p>
                    <h2>5. ОПРЕДЕЛЕНИЯ</h2>
                    <p>5.1. Покупатель - физическое или юридическое лицо, принявшее в полном объеме и без исключений условия оферты (совершившее акцепт оферты) в соответствии с п. 1.4.</p>
                    <p>настоящего договора.</p>
                    <p>5.2. Продавец &ndash; ООО <strong>&laquo;</strong>Арсеналъ Металлоконструкция&raquo;.</p>
                    <p>5.3. Интернет-магазин - интернет-сайт, имеющий адрес в сети интернет &nbsp;АрсеналЪ-1.рф <a href="арсеналъ-1.рф">&nbsp;</a>принадлежащий Продавцу и предназначенный для продажи Продавцом Покупателям на основании оферты товаров, принадлежащих Продавцу. 5.4. Сайт - интернет-сайт, имеющий адрес в сети интернет &nbsp;АрсеналЪ-1.рф</p>
                    <p>5.5. Каталог &ndash; информация о товарах, размещенная в интернет-магазине.</p>
                    <p>5.6. Товар &ndash; <a href="https://xn---1-6kcay1bmwj8g.xn--p1ai/catalog/grids">Решетки,</a> <a href="https://xn---1-6kcay1bmwj8g.xn--p1ai/catalog/conditioner-protection">Защиты кондиционера,</a> <a href="https://xn---1-6kcay1bmwj8g.xn--p1ai/catalog/anti-theft">Противоугонные устройства</a>, <a href="https://xn---1-6kcay1bmwj8g.xn--p1ai/catalog/woodcutters">Дровницы</a>, <a href="https://xn---1-6kcay1bmwj8g.xn--p1ai/catalog/birdhouses">Кормушки для птиц</a>, <a href="https://xn---1-6kcay1bmwj8g.xn--p1ai/catalog/pergolias">Перголы</a>, <a href="https://xn---1-6kcay1bmwj8g.xn--p1ai/catalog/swings">Качели</a>, <a href="https://xn---1-6kcay1bmwj8g.xn--p1ai/catalog/flags">Флагштоки</a> реализуемые Продавцом в интернет-магазине.</p>
                    <p>5.7. Заказ - решение Покупателя приобрести товар, оформленное в интернет-магазине.</p>
                    <p>5.8. Место исполнения договора - место (адрес), указанное Покупателем, по которому доставляется товар Покупателю силами Продавца, или склад Продавца, в случае отказа Покупателя от доставки товара силами Продавца, или территория перевозчика, договор с которым заключил Покупатель.</p>
                    <p>5.9. Представитель &ndash; физическое лицо, предъявившее квитанцию или иной документ, свидетельствующий о заключении договора. Представитель юридического лица кроме вышеуказанных документов обязан предъявить доверенность на получение товара и паспорт.</p>
                    <p>5.10. Перевозчик &ndash; юридическое лицо или индивидуальный предприниматель, принявшие на себя по договору перевозки обязанность доставить вверенный ему отправителем товар из пункта отправления в пункт назначения, а также выдать товар получателю. Договор перевозки с перевозчиком заключается Продавцом. 5.11. Стороны &ndash; совместно Покупатель и Продавец.</p>
                    <p>&nbsp;</p>   
                    <h2>6. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА КУПЛИ-ПРОДАЖИ</h2>
                    <p>6.1. Покупатель может оформить заказ самостоятельно, на любой товар, представленный на сайте интернет-магазина, на условиях Договора купли-продажи (публичной оферты интернет-магазина).</p>
                    <p>6.2. При оформлении заказа в интернет-магазине, Покупатель обязан предоставить о себе информацию:</p>
                    <ul>
                    <li>Ф.И.О. (для физических лиц) или полное наименование, ИНН (для юридических лиц) Покупателя Товара;</li>
                    <li>адрес доставки Товара;</li>
                    <li>контактный телефон и электронную почту Покупателя Товара.</li>
                    </ul>
                    <p>6.3. Волеизъявление Покупателя осуществляется посредством внесения соответствующих данных в форму заказа в интернет-магазине либо подачей заявки через менеджера интернет-магазина. 6.4. Интернет-магазин не редактирует информацию о Покупателе.</p>
                    <p>&nbsp;</p>
                    <h2>7. ИНФОРМАЦИЯ О ТОВАРЕ</h2>
                    <p>7.1. Товар представлен на сайте через фото-образцы, являющиеся собственностью интернет- магазина.</p>
                    <p>7.2. Каждый фото-образец сопровождается текстовой информацией: наименованием, размерным рядом (при необходимости), ценой и описанием товара, гарантийных сроках.</p>
                    <p>7.3. Все информационные материалы, представленные в интернет-магазине, носят справочный характер и не могут в полной мере передавать информацию о свойствах и характеристиках товара, включая цвета, размеры и формы. В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик товара, Покупатель перед оформлением заказа, может обратиться за консультацией к Продавцу.&nbsp;</p>
                    <p>7.4. По просьбе Покупателя менеджер интернет-магазина может предоставить прочую информацию, необходимую и достаточную, с точки зрения Покупателя, для принятия им решения о покупке товара.</p>
                    <p>7.5. Покупатель уведомлен о том, что, приобретая товар со скидкой, установленной в связи с его, недостатками (дефектами), он лишается права ссылаться на них в дальнейшем.</p>
                    <p>7.6. Покупатель уведомлен Продавцом о том, что товар, указанный в счете отдельными позициями в любом случае не является комплектом.</p>
                    <p><strong>&nbsp;</strong></p>
                    <h2>8.ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
                    <p>8.1. Продавец обязан:</p>
                    <p>8.1.1. Поставить товар в соответствии с условиями Заказа.</p>
                    <p>8.1.2. Гарантировать соответствие качества Товара требованиям качества для аналогичных Товаров на территории РФ. Обеспечить гарантийные обязательства в соответствии с условиями настоящего Договора.</p>
                    <p>8.1.3. В случае изменения срока поставки, немедленно информировать Покупателя об изменении условий поставки с целью получения согласия на новые условия выполнения Заказа в целом, либо в части. Продавец информирует Покупателя посредством телефонной связи либо электронной почты, указанной Покупателем.</p>
                    <p>8.1.4. Рассмотреть претензии в соответствии с условиями настоящего Договора и принять меры к их удовлетворению.</p>
                    <p>8.1.5. Осуществлять замену Товара либо возврат денежных средств на основании условий настоящего Договора.</p>
                    <p>8.2. Продавец имеет право:</p>
                    <p>8.2.1. Не принимать претензий по возвращенному товару при отсутствии накладной, упаковки или потере ее товарного вида, обнаружении внешних повреждений Товара, следов использования Товара не по его прямому назначению, следов замены или установки отдельных узлов или деталей, следов эксплуатации.</p>
                    <p>8.2.2. Не принимать претензий по возврату Товара надлежащего качества, имеющему индивидуально-определенные свойства.</p>
                    <p>8.2.3. В случае необходимости проводить проверку качества и экспертизу Товара.</p>
                    <p>Привлекать для исполнения настоящего Договора третьих лиц.</p>
                    <p>8.3. Покупатель обязан:</p>
                    <p>8.3.1. Предоставить при оформлении заказа точное наименование требуемого Товара, его номер по каталогу производителя, или все данные необходимые для точного определения требуемого Товара (цвет, вариант исполнения, количество). В случае самостоятельного оформления Заказа нести полную ответственность за подбор Товара по каталогам производителя данного Товара.</p>
                    <p>8.3.2. Оплачивать Товар, согласно Заказам, в сроки и по указанной в них цене.</p>
                    <p>8.3.3. При получении Товара Покупатель обязан проверить количество, комплектность, ассортимент Заказа. В случае недостачи, либо ненадлежащего вида упаковки или Товара, сообщить Продавцу в однодневный срок.</p>
                    <p>8.4. Покупатель имеет право:</p>
                    <p>8.4.1. Потребовать возврата предоплаты в случае невозможности исполнения Продавцом условий Заказа.</p>
                    <p>8.4.2. Внести изменения в Заказ до начала его исполнения Продавцом.</p>
                    <p>8.4.3. До передачи ему Товара отказаться от исполнения договора, за исключением случаев поставки товара &laquo;Под заказ&raquo;, при условии возмещения Продавцу транспортных расходов, понесенных Продавцом в связи с совершением действий по выполнению настоящего Договора.</p>
                    <p>&nbsp;</p>
                    <h2>9. ПОРЯДОК ПРИОБРЕТЕНИЯ ТОВАРА</h2>
                    <p>9.1. Покупатель вправе оформить заказ на любой товар, представленный в интернет-магазине. Каждый товар может быть заказан в любом количестве, но не более количества имеющегося на складе Продавца в момент оформления заказа. Исключения из указанного правила указаны в описании каждого товара в случае проведения акций, снятия товара с продажи и т.п.</p>
                    <p>9.2. Заказ может быть оформлен Покупателем по телефонам, указанным на сайте или оформлен самостоятельно на сайте. Подробности оформления заказа через сайт описаны на сайте &nbsp;АрсеналЪ-1.рф</p>
                    <p>9.3. После оформления заказа Продавец на телефон Покупателя отправляет сообщение с подтверждением принятия заказа.</p>
                    <p>9.4. При отсутствии товара на складе менеджер интернет-магазина обязан поставить в известность об этом Покупателя (по телефону или посредством электронной почты).</p>
                    <p>9.5. При оформлении Покупателем товара &laquo;Под заказ&raquo; срок поставки согласовывается менеджером при первом контакте с Покупателем после оформления заказа и исчисляется в календарных днях, начиная с момента зачисления денежных средств (авансового платежа) на расчетный счет или в кассу Продавца.</p>
                    <p><strong>&nbsp;</strong></p>
                    <h2>10. ЦЕНА ТОВАРА</h2>
                    <p>10.1. Цена товара в интернет-магазине указана в рублях РФ за единицу товара.</p>
                    <p>10.2. Указанная на сайте цена товара может быть изменена интернет-магазином в одностороннем порядке, при этом цена на заказанный и оплаченный Покупателем товар изменению не подлежит.</p>
                    <p>10.3. Полная стоимость заказа состоит из каталожной стоимости товара и стоимости Доставки транспортной компанией и условий доставки.</p>
                    <p>10.4. Условия и стоимость услуг по доставке, предоставляемых Покупателю Продавцом при покупке товара в интернет - магазине указана на сайте &nbsp;АрсеналЪ-1.рф <a href="арсеналъ-1.рф">&nbsp;</a>в разделе &laquo;Корзина&raquo;.</p>
                    <p>&nbsp;</p>
                    <h2>11. ОПЛАТА ТОВАРА</h2>
                    <p>11.1. Способы и порядок оплаты товара указаны на сайте в разделе &laquo;Корзина&raquo;. При необходимости порядок и условия оплаты заказанного товара оговариваются Покупателем с менеджером интернет-магазина.</p>
                    <p>11.2. После поступления денежных средств на счет Продавца, менеджер интернет0магазина согласовывает с Покупателем срок доставки. Обязанность Покупателя по уплате цены товара считается исполненной с момента зачисления соответствующих денежных средств на расчетный счет Продавца.</p>
                    <p>11.3. Покупатель оплачивает заказ любым способом, выбранным в интернет-магазине.</p>
                    <p>11.4. Расчеты Сторон при оплате заказа осуществляются в российских рублях.</p>
                    <p>&nbsp;</p>
                    <h2>12. ДОСТАВКА ТОВАРОВ</h2>
                    <p>12.1. Способы, порядок и сроки доставки товара указаны на сайте &nbsp;АрсеналЪ-1.рф <a href="арсеналъ-1.рф">&nbsp;</a>&nbsp;в разделе &laquo;Доставка&raquo;.</p>
                    <p>Порядок и условия доставки заказанного товара оговариваются Покупателем с менеджером Интернет-магазина.</p>
                    <p>12.2. Доставка товара Продавцом:</p>
                    <p>12.2.1. Переход права собственности и риск случайной гибели, утраты или повреждения товара переходит к Покупателю в момент передачи товара Покупателю или Представителю по адресу доставки.</p>
                    <p>12.2.2. При доставке товар передается Покупателю или Представителю покупателя под роспись на накладной, предоставленной Продавцом.</p>
                    <p>12.3. Доставка товара Перевозчиком:</p>
                    <p>12.3.1. Право собственности и риск случайной гибели, утраты или повреждения товара переходит от Продавца на Покупателя или Перевозчика (в соответствии с заключенным между Покупателем и Перевозчиком договором) с момента передачи товара перевозчику в месте исполнения договора при подписании Сторонами акта приёма товара (товарной накладной и/или транспортной накладной и/или товарно-транспортной накладной).</p>
                    <p>12.3.2. Обязательство по передачи товара Покупателю, в том числе п. 12.3.1., считается исполненным с момента передачи товара Перевозчику.</p>
                    <p>12.3.3. Стоимость доставки товара в рамках каждого заказа рассчитывается исходя из веса всех заказанных товаров, адреса доставки заказа, расценок Перевозчика и оплачивается Покупателем самостоятельно.</p>
                    <p>12.4. Покупатель обязан принять товар по количеству и ассортименту в момент его приемки.</p>
                    <p>12.5. При получении товара Покупатель должен в присутствии представителя Продавца (перевозчика) проверить его соответствие товарной накладной, удостовериться по наименованию товара в количестве, качестве, комплектности товара.</p>
                    <p>12.6. Покупатель или Представитель при приемке товара подтверждает своей подписью в товарной накладной, что не имеет претензий к внешнему виду и комплектности товара.</p>
                    <p>&nbsp;</p>
                    <h2>13. ГАРАНТИИ НА ТОВАР</h2>
                    <p>13.1. Информация о гарантии на товар, предоставлена в карточке товара на сайте интернет-магазина &nbsp;АрсеналЪ-1.рф</p>
                    <p>13.2. Продавец оставляет за собой право провести независимую экспертизу качества Товара, в том числе в своем экспертном центре при обращении Покупателя с претензией в случае обнаружения недостатков Товара в течении гарантийного срока.</p>
                    <p>13.3. Гарантия не распространяется:</p>
                    <p>&nbsp;Нормальный износ;</p>
                    <p>&nbsp;Повреждения при доставке Товара транспортной компанией;</p>
                    <p>&nbsp;Неправильная эксплуатация Товара;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <p>&nbsp;Использование Товара не по назначению;</p>
                    <p>&nbsp;Наличие видимых попыток самостоятельного устранения недостатков, ремонта и замены запасных частей;</p>
                    <p>&nbsp;Внешние повреждения Товара;</p>
                    <p>&nbsp;Дефекты, неисправности или коррозия, возникшие в результате воздействия химических веществ;</p>
                    <p>&nbsp;Расходные материалы;</p>
                    <p>&nbsp;На дефекты, возникшие вследствие неправильного ухода за Товаром.</p>
                    <p>13.4. Покупатель вправе требовать гарантийного обмена Товара в период гарантийного срока при соблюдении всех требований, установленных настоящим Договором.</p>
                    <p>13.5. При выявлении Покупателем недостатков Товара в течение гарантийного срока. Покупатель обязуется предоставить Продавцу:</p>
                    <ol>
                    <li>Товар;</li>
                    <li>Письменную претензию с описанием недостатков;</li>
                    <li>Документы, полученные при приобретении Товара (накладная с печатью, чек);</li>
                    <li>Оригинальную упаковку.</li>
                    </ol>
                    <p>13.6. Срок рассмотрения претензии составляет 30 (Тридцать) календарных дней с момента ее получения от Покупателя.</p>
                    <p>&nbsp;</p>
                    <h2>14. ВОЗВРАТ ТОВАРА</h2>
                    <p>14.1. Покупатель вправе отказаться от заказа только до момента его получения. <em>&nbsp;</em>В случае обнаружения брака или каких-либо производственных дефектов в товаре возможен его обмен на аналогичный товар надлежащего качества.</p>
                    <p>14.2. Возврат и обмен Товара НАДЛЕЖАЩЕГО качества, производится по желанию Покупателя в связи с не устраивающими его свойствами (несоответствие формы, габаритов, фасона, расцветки, размера.), не относящегося к Товару, не подлежащему обмену или возврату или имеющему индивидуально-определенные свойства. Возврат производится в течение 7 (семи) дней, обмен производится в течение 14 (четырнадцати) дней после его получения, по письменному заявлению Покупателя, после осмотра техническим специалистом, без следов установки, в оригинальной упаковке, при условии компенсации Покупателем транспортных (и иных видов) расходов, связанных с&nbsp; доставкой Заказа Покупателю. Возврат такого Товара осуществляется за счет Покупателя. Возврат и обмен Товара НАДЛЕЖАЩЕГО качества не распространяется на товар, оформленный в разделе &laquo;Под заказ&raquo;.&nbsp;&nbsp;&nbsp;</p>
                    <p>14.3. Возврат и обмен товара НЕ НАДЛЕЖАЩЕГО качества производится в течение гарантийного срока по письменному заявлению Покупателя, после проведения экспертизы на предмет установления причин выхода из строя или выявленных недостатков. Срок на проведение экспертизы и ответ Покупателю на предъявленную им Претензию устанавливается в 30 рабочих дней. Возврат и обмен возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара (товарная накладная).</p>
                    <p>14.4. Срок возврата денежных средств составляет 10 рабочих дней с момента возврата Товара (в случае возврата Товара надлежащего качества) на склад Продавца, либо с момента заключения экспертизы о признании товара некачественным или дефектным не по вине Покупателя.</p>
                    <p>Возврат Продавцом денежных средств Покупателю осуществляется тем же способом, каким Покупатель произвел оплату Продавцу.</p>
                    <p>&nbsp;</p>
                    <h2>15. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
                    <p>15.1. Стороны несут ответственность в соответствии с законодательством РФ.</p>
                    <p>15.2. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования им товаров, заказанных в интернет-магазине.</p>
                    <p>15.3. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по договору на время действия непреодолимой силы.</p>
                    <p>&nbsp;</p>
                    <h2>16. ПРОЧИЕ УСЛОВИЯ</h2>
                    <p>16.1. К отношениям между Покупателем и Продавцом применяется законодательство Российской Федерации.</p>
                    <p>16.2. При необходимости Продавец и Покупатель вправе в любое время оформить договор купли- продажи товара в форме письменного двухстороннего соглашения, не противоречащего положениям настоящей оферты.</p>
                    <p>16.3. В случае возникновения вопросов и претензий со стороны Покупателя, он должен обратиться по телефону: <strong><em>+7 (921) 933-72-14</em></strong> или <strong>E-mail: 6220095@</strong><strong>mail</strong><strong>.</strong><strong>ru</strong>&nbsp;</p>
                    <p>16.4. Настоящий договор вступает в силу с даты акцепта Покупателем настоящей оферты и действует до полного исполнения обязательств Сторонами.</p>
                    <p>16.5. Все споры и разногласия, возникающие при исполнении Сторонами обязательств по настоящему договору, решаются путем переговоров. В случае невозможности их устранения, Стороны имеют право обратиться за судебной защитой своих интересов.</p>
                    <p>16.6. Интернет-магазин оставляет за собой право расширять и сокращать товарное предложение на сайте, регулировать доступ к покупке любых товаров, а также приостанавливать или прекращать продажу любых товаров по своему собственному усмотрению.</p>
                    <p>&nbsp;</p>
                    <h2>17. АДРЕС И РЕКВИЗИТЫ ПРОДАВЦА</h2>
                    <table width="584">
                    <tbody>
                    <tr>
                    <td width="296">
                    <p>Полное наименование организации</p>
                    </td>
                    <td width="288">
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Общество &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; с &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ограниченной</p>
                    <p>ответственностью "Арсеналъ Металлоконструкция"</p>
                    </td>
                    </tr>
                    <tr>
                    <td width="296">
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Краткое &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; наименование</p>
                    <p>организации</p>
                    </td>
                    <td width="288">
                    <p>ООО " Арсеналъ Металлоконструкция "</p>
                    </td>
                    </tr>
                    <tr>
                    <td width="296">
                    <p>Юридический адрес</p>
                    </td>
                    <td width="288">
                    <p><a href="https://yandex.ru/maps/?source=exp-counterparty_entity&amp;text=194100,%20%D0%93.%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3,%20%D0%B2%D0%BD.%D1%82%D0%B5%D1%80.%D0%B3.%20%D0%9C%D1%83%D0%BD%D0%B8%D1%86%D0%B8%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D0%9E%D0%BA%D1%80%D1%83%D0%B3%20%D0%A1%D0%B0%D0%BC%D0%BF%D1%81%D0%BE%D0%BD%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%BE%D0%B5,%20%D0%BF%D1%80-%D0%BA%D1%82%20%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%BE%D0%B9%20%D0%A1%D0%B0%D0%BC%D0%BF%D1%81%D0%BE%D0%BD%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%B8%D0%B9,%20%D0%B4.%2080,%20%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%20%D0%90"><br /> 194100, Г.Санкт-Петербург, вн.тер.г. Муниципальный Округ Сампсониевское, пр-кт Большой Сампсониевский, д. 80, литера А</a></p>
                    <p>&nbsp;</p>
                    </td>
                    </tr>
                    <tr>
                    <td width="296">
                    <p>Почтовый адрес</p>
                    </td>
                    <td width="288">
                    <p><a href="https://yandex.ru/maps/?source=exp-counterparty_entity&amp;text=194100,%20%D0%93.%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3,%20%D0%B2%D0%BD.%D1%82%D0%B5%D1%80.%D0%B3.%20%D0%9C%D1%83%D0%BD%D0%B8%D1%86%D0%B8%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D0%9E%D0%BA%D1%80%D1%83%D0%B3%20%D0%A1%D0%B0%D0%BC%D0%BF%D1%81%D0%BE%D0%BD%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%BE%D0%B5,%20%D0%BF%D1%80-%D0%BA%D1%82%20%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%BE%D0%B9%20%D0%A1%D0%B0%D0%BC%D0%BF%D1%81%D0%BE%D0%BD%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%B8%D0%B9,%20%D0%B4.%2080,%20%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%20%D0%90"><br /> 194100, Г.Санкт-Петербург, вн.тер.г. Муниципальный Округ Сампсониевское, пр-кт Большой Сампсониевский, д. 80, литера А</a></p>
                    <p>&nbsp;</p>
                    </td>
                    </tr>
                    <tr>
                    <td width="296">
                    <p>ИНН/КПП</p>
                    </td>
                    <td width="288">
                    <p><br /> 7804305823/ 780201001</p>
                    <p>&nbsp;</p>
                    </td>
                    </tr>
                    <tr>
                    <td width="296">
                    <p>ОГРН</p>
                    </td>
                    <td width="288">
                    <p>1047855113426</p>
                    </td>
                    </tr>
                    <tr>
                    <td width="296">
                    <p>Банковские реквизиты</p>
                    </td>
                    <td width="288">
                    <p>р/сч &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 40702810580000003184 Филиал ОПЕРУ Банка ВТБ (ПАО) в Санкт-Петербурге, г.Санкт Петербург БИК: 044030704</p>
                    <p>к/сч 30101810200000000704</p>
                    </td>
                    </tr>
                    <tr>
                    <td width="296">
                    <p>Генеральный директор</p>
                    </td>
                    <td width="288">
                    <p>Черемис Елена Юрьевна</p>
                    </td>
                    </tr>
                    </tbody>
                    </table>
                    <p>&nbsp;</p>
 


                </div> 
                    
                    <Feedback />

                </main>
                <Footer/>
 
        </div>
    )
}

export default Offer;